import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { NavLink, Link, useNavigate } from "react-router-dom";
import Notiflix from "notiflix";

//ANT DESIGN IMPORTS
import {
  Row,
  Col,
  Card,
  Statistic,
  Button,
  Typography,
  Descriptions,
  Tag,
  Radio,
  Skeleton,
  Input,
  Divider,
} from "antd";
import {
  DoubleRightOutlined,
  ReloadOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";

//PROJECT IMPORTS
import Main from "../../components/layout/Main";
import { BOM } from "../../services/definedMethod";
import cnt from "../../services/definedConstant";
import { apiConfig, labelConfig, staticConfig } from "./admin.config";
import { BackToSpecificScreen } from "../../components/Facility";

const { Text } = Typography;

const ManageTransfer = (props) => {
  const navigate = useNavigate();
  const { userData } = props;
  const { PROFILE_ID, ROLE_SHORT, session, isAdmin } = userData;
  const [transferList, setTransferList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [statStatus, setStatStatus] = useState("");
  const [transferCount, setTransferCount] = useState({});
  const [originalList, setOriginalList] = useState([]);

  useEffect(() => {
    if (!isAdmin) return navigate("/");

    getTransfer("pending");
    getTransferCount();
  }, []);

  const getTransfer = (status) => {
    let body = {
      profileId: PROFILE_ID,
      status,
      token: session,
    };
    setIsLoading(true);
    BOM.FetchReqAction(body, apiConfig.GetTransferForAdmin, (err, res) => {
      if (err) {
        // for (let index = 0; index < err?.errorData?.length; index++) {
        //   const element = err?.errorData[index];

        //   BOM.AlertMsg(cnt.DANGER, element);
        // }
        BOM.AlertMsg(cnt.DANGER, err?.errorData[0]);
        setIsLoading(false);
      } else {
        const objList = res.transferListing;
        setTransferList(objList);
        setStatStatus(status);
        setOriginalList(objList);
        //STOP LOAD
        setIsLoading(false);
      }
    });
  };
  const getTransferCount = () => {
    let body = {
      profileId: PROFILE_ID,
      token: session,
    };
    setIsLoading(true);
    BOM.FetchReqAction(body, apiConfig.GetAdminTransferCount, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err?.errorData[0]);
        setIsLoading(false);
      } else {
        const objDetail = res.transferCountDetail;
        setTransferCount(objDetail);
        //STOP LOAD
        setIsLoading(false);
      }
    });
  };
  const onChange = (e) => getTransfer(e.target.value);
  const onRefresh = () => {
    getTransfer(statStatus);
  };
  function handleSearchInList(searchKey) {
    searchKey = searchKey.target.value;

    if (!searchKey) {
      setTransferList(originalList);
      return;
    }
    const filterBySearch = originalList.filter(
      (item) =>
        item?.TRF_CODE.toLowerCase()?.includes(searchKey?.toLowerCase()) ||
        item?.SENDER_USERNAME.toLowerCase()?.includes(searchKey?.toLowerCase())
    );
    setTransferList(filterBySearch);
  }
  return (
    <Main>
      <Row gutter={[24, 0]}>
        <Col span={24} md={24} className="mb-24">
        <BackToSpecificScreen route={"/admin-dashboard"}/>
          <Card
            className="header-solid h-full"
            // title={
            //   <>
            //     <Button
            //       shape="round"
            //       size="small"
            //       onClick={onRefresh}
            //       icon={<ReloadOutlined />}
            //       loading={isLoading}
            //     >
            //       {labelConfig.refresh}
            //     </Button>
            //   </>
            // }
            bordered={false}
            extra={
              <>
                <Radio.Group
                  onChange={onChange}
                  defaultValue="pending"
                  className="radio-title-button"
                  size="small"
                  style={{ marginTop: "10px", marginBottom: "20px" }}
                >
                  <Radio.Button value="pending">
                    {`${labelConfig.pending.toUpperCase()} ( ${
                      transferCount.pending
                    } )`}
                  </Radio.Button>
                  <Radio.Button value="paid">
                    {`${labelConfig.paid.toUpperCase()} ( ${
                      transferCount.paid
                    } )`}
                  </Radio.Button>
                  <Radio.Button value="ready">
                    {`${labelConfig.ready.toUpperCase()} ( ${
                      transferCount.ready
                    } )`}
                  </Radio.Button>
                  <Radio.Button value="completed">
                    {`${labelConfig.completed.toUpperCase()} ( ${
                      transferCount.completed
                    } )`}
                  </Radio.Button>
                  <Radio.Button value="cancelled">
                    {`${labelConfig.cancelled.toUpperCase()} ( ${
                      transferCount.cancelled
                    } )`}
                  </Radio.Button>
                </Radio.Group>
              </>
            }
          >
            <Col span={24} md={24} className="mb-24">
              <Input
                placeholder="Search by Sender Username or Reference Code"
                onChange={handleSearchInList}
                allowClear
              />
            </Col>
            {!isLoading && transferList.length === 0 ? (
              <Row>No {statStatus} transfer</Row>
            ) : (
              <Row gutter={[24, 24]}>
                <Skeleton loading={isLoading} active avatar />

                {transferList.map((item, index) => (
                  <Col span={24} key={index}>
                    <Card
                      className="card-billing-info"
                      bordered="false"
                      onClick={() =>
                        navigate("/admin-detail-transfer", { state: item })
                      }
                    >
                      <Descriptions
                        // bordered
                        size={"small"}
                        extra={<></>}
                        items={[
                          {
                            label: "#",
                            children: item.TRF_ID,
                          },
                          {
                            label: labelConfig.date,
                            children: item.MAKER_DATE,
                          },
                          {
                            label: labelConfig.status,
                            children: (
                              <>
                                {staticConfig.handleFlgStatus(item.TRF_STATUS)}
                              </>
                            ),
                          },
                          {
                            label: labelConfig.trnRefenceCode,
                            children: <Tag>{item.TRF_CODE}</Tag>,
                          },
                          {
                            label: labelConfig.sender,
                            children: item.SENDER_FULLNAME,
                          },
                          {
                            label: labelConfig.receiver,
                            children: item.RECIPIENT_FULLNAME,
                          },
                          {
                            label: labelConfig.amount,
                            children: (
                              <Tag>{`${item.CURRENCY_SYMBOL} ${item.TRF_AMOUNT}`}</Tag>
                            ),
                          },
                          {
                            label: `${labelConfig.country} ( ${item.RCP_COUNTRY_SORTNAME} )`,
                            children: (
                              <>
                                <img
                                  className="country-flag"
                                  src={item.RCP_COUNTRY_FLAG}
                                  title={item.RCP_COUNTRY_SORTNAME}
                                />
                              </>
                            ),
                          },
                        ]}
                      />
                      <div className="col-action">
                        <Button type="link" className="darkbtn">
                          <DoubleRightOutlined />
                          VIEW MORE
                        </Button>
                      </div>
                    </Card>
                  </Col>
                ))}
              </Row>
            )}
          </Card>
        </Col>
      </Row>
    </Main>
  );
};

const mapStateToProps = (state) => ({
  userData: state.AuthenticationData.userData,
});

export default connect(mapStateToProps, null)(ManageTransfer);
