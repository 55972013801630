import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

//ANT DESIGN IMPORTS
import {
  Row,
  Col,
  Card,
  Divider,
  Button,
  Form,
  Input,
  InputNumber,
} from "antd";

//PROJECT IMPORTS
import Main from "../../components/layout/Main";
import { BOM } from "../../services/definedMethod";
import cnt from "../../services/definedConstant";
import { apiConfig, labelConfig } from "./transfer.config";

const SearchTransfer = (props) => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { userData } = props;
  const { PROFILE_ID, session } = userData;
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = (formValues) => {
    setIsLoading(true);
    let body = {
      profileId: PROFILE_ID,
      ...formValues,
      token: session,
    };
    // console.log(body);
    // return;
    BOM.FetchReqAction(body, apiConfig.SearchTransfer, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err.message);
        setIsLoading(false);
      } else {
        navigate("/detail-transfer", { state: res.transferDetail})
        setIsLoading(false);
      }
    });
  };

  return (
    <Main>
      <Row gutter={[24, 0]}>
        <Col span={24} md={24} className="mb-24">
          <Card className="header-solid h-full">
            <Divider orientation="left">{labelConfig.receiveTransfer}</Divider>

            <Form layout={"vertical"} onFinish={onSubmit}>
              <Row>
                <Col span={24} style={{ padding: "0px 8px" }}>
                  <Form.Item
                    label={labelConfig.receiverLastName}
                    name="receiverLastname"
                    rules={[
                      {
                        required: true,
                        message: BOM.CheckReqFld(labelConfig.receiverLastName),
                      },
                    ]}
                  >
                    <Input
                      maxLength={40}
                      placeholder={BOM.handlePlaceholderMsg(
                        `${labelConfig.receiverLastName} *`
                      )}
                    />
                  </Form.Item>
                </Col>
                <Col span={24} style={{ padding: "0px 8px" }}>
                  <Form.Item
                    label={labelConfig.receiverFirstName}
                    name="receiverFirstname"
                    rules={[
                      {
                        required: true,
                        message: BOM.CheckReqFld(labelConfig.receiverFirstName),
                      },
                    ]}
                  >
                    <Input
                      maxLength={40}
                      placeholder={BOM.handlePlaceholderMsg(
                        `${labelConfig.receiverFirstName} *`
                      )}
                    />
                  </Form.Item>
                </Col>
                <Col span={24} style={{ padding: "0px 8px" }}>
                  <Form.Item
                    label={labelConfig.trnRefenceCode}
                    name="transferReferenceCode"
                    rules={[
                      {
                        required: true,
                        message: BOM.CheckReqFld(labelConfig.trnRefenceCode),
                      },
                      {
                        min:8,
                        message: labelConfig.minimumOfHeightCharacters,
                      },
                      {
                        max:8,
                        message: labelConfig.maximumOfHeightCharacters,
                      },
                    ]}
                  >
                    <Input
                      maxLength={8}
                      className="inputFieldNumber"
                      placeholder={BOM.handlePlaceholderMsg(
                        `${labelConfig.trnRefenceCode} *`
                      )}
                    />
                  </Form.Item>
                </Col>
                {/* <Col span={24}></Col> */}
              </Row>
              <Form.Item>
                <Button
                      htmlType="submit"
                      className="of-primaryBtnColor"
                      loading={isLoading}
                    >
                      {labelConfig.submit}
                    </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </Main>
  );
};

const mapStateToProps = (state) => ({
  userData: state.AuthenticationData.userData,
});

export default connect(mapStateToProps, null)(SearchTransfer);
