import React, { useState, useEffect, useRef } from "react";
import { Menu, Button } from "antd";
import { NavLink, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import logo from "../../assets/images/gdegLogo.png";
import menuConfig from "../../config/menu.config";
import {
  DashboardOutlined,
  TransactionOutlined,
  PieChartOutlined,
  UserOutlined,
} from "@ant-design/icons";

function Sidenav(props) {
  const { color, userData } = props;
  const { isAdmin } = userData;
  const { pathname } = useLocation();
  const page = pathname.replace("/", "");

  const menu = [
    {
      label: "Dashboard",
      key: isAdmin ? "/admin-dashboard" : "/dashboard",
      icon: <DashboardOutlined className="gg-menu-icon" />,
    },
    {
      label: "Transfer",
      key: "/manage-transfer",
      key: isAdmin ? "/admin-manage-transfer" : "/manage-transfer",
      icon: <TransactionOutlined className="gg-menu-icon" />,
    },
    // {
    //   label: "Report",
    //   key: "/report",
    //   icon: <PieChartOutlined className="gg-menu-icon" />,
    // },
    {
      label: "Profile",
      key: "/profile",
      icon: <UserOutlined className="gg-menu-icon" />,
    },
  ];

  useEffect(() => {}, []);

  return (
    <>
      <div className="brand">
        <img src={logo} alt="" />
        {/* <span>GdeG</span> */}
      </div>
      <hr />
      <Menu theme="light" mode="inline">
        {menu.map((item, index) => (
          <Menu.Item key={index}>
            <NavLink to={item.key}>
              <span
                className="icon"
                style={{
                  // background: page === item.label.toLowerCase() ? color : "",
                  border: "1px solid var(--primaryColor)",
                }}
              >
                {item.icon}
              </span>
              <span className="label">{item.label}</span>
            </NavLink>
          </Menu.Item>
        ))}
      </Menu>
      {/* <div className="aside-footer">
        <div
          className="footer-box"
          style={{
            background: color,
          }}
        >
          <span className="icon" style={{ color }}>
            {dashboard}
          </span>
          <h6>Need Help?</h6>
          <p>Please check our docs</p>
          <Button type="primary" className="ant-btn-sm ant-btn-block">
            DOCUMENTATION
          </Button>
        </div>
      </div> */}
    </>
  );
}

const mapStateToProps = (state) => ({
  userData: state.AuthenticationData.userData,
});

export default connect(mapStateToProps, null)(Sidenav);
