import { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { NavLink, Link, useNavigate } from "react-router-dom";

// ANT DESIGN IMPORTS
import {
  Row,
  Col,
  Card,
  Button,
  List,
  Descriptions,
  Avatar,
  Radio,
  Switch,
  Upload,
  message,
} from "antd";

import BgProfile from "../../assets/images/bg-profile.jpg";
import profilavatarMale from "../../assets/images/profile_pic_male.svg";
import profilavatarFemale from "../../assets/images/profile_pic_femal.svg";
import Main from "../../components/layout/Main";
import { BackToPreviousScreen } from "../../components/Facility";

function Profile(props) {
  const navigate = useNavigate();

  const { userData } = props;
  const fullName = `${userData.USER_FIRSTNAME} ${userData.USER_LASTNAME}`
  const [imageURL, setImageURL] = useState(false);
  const [, setLoading] = useState(false);

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      setLoading(false);
      return;
    }
    if (info.file.status === "done") {
      getBase64(info.file.originFileObj, (imageUrl) => {
        setLoading(false);
        setImageURL(false);
      });
    }
  };

  const onSelectButton = (e)=>{
    switch (e.target.value?.toLowerCase()) {
      case 'change-password':
          navigate("/change-password")
        break;
    
      default:
       return 0
    }
  }

  return (
    <Main>
      <div
        className="profile-nav-bg"
        // style={{ backgroundImage: "url(" + BgProfile + ")" }}
      ></div>
      <Card
        className="card-profile-head"
        bodyStyle={{ display: "none" }}
        title={
          <Row justify="space-between" align="middle" gutter={[24, 0]}>
            <Col span={24} md={12} className="col-info">
              <Avatar.Group>
                <Avatar size={74} shape="square" src={userData?.USER_GENDER?.toLowerCase() === 'male' ? profilavatarMale : profilavatarFemale} />

                <div className="avatar-info">
                  <h4 className="font-semibold m-0">{fullName}</h4>
                  <p>{userData?.ROLE_NAME}</p>
                </div>
              </Avatar.Group>
            </Col>
            <Col
              span={24}
              md={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Radio.Group defaultValue="a" onChange={onSelectButton}>
                <Radio.Button value="change-password">Change Password</Radio.Button>
                {/* <Radio.Button value="b">TEAMS</Radio.Button> */}
                {/* <Radio.Button value="c">PROJECTS</Radio.Button> */}
              </Radio.Group>
            </Col>
          </Row>
        }
      ></Card>
    </Main>
  );
}
const mapStateToProps = (state) => ({
  userData: state.AuthenticationData.userData,
});

export default connect(mapStateToProps, null)(Profile);