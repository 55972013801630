// import Dashboard from "../pages/Dashboard";
import Dashboard from "../pages/dashboard/Dashboard";
import PageNotFound from "../components/PageNotFound";
export default [
  {
    path: "/dashboard",
    component: Dashboard,
    isProtected: true,
  },
  {
    path: "*",
    component: PageNotFound,
    isProtected: true,
  },
];
