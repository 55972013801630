import React from "react";

//ANT DESIGN IMPORTS
import {
  Col,
  Form,
  Input,
  Select,
} from "antd";

//PROJECT IMPORTS
import { BOM } from "../../../services/definedMethod";
import { labelConfig } from "../recipient.config";


const BankFields = (props) => {
    return (
      <>
        <Col span={24} style={{ padding: "0px 8px" }}>
          <Form.Item
            label={labelConfig.bankName}
            name="rcpBankName"
            rules={[
              {
                required: true,
                message: BOM.CheckReqFld(labelConfig.bankName),
              },
            ]}
          >
            <Select
              filterOption={(input, option) =>
                (option?.label?.toLowerCase() ?? "").includes(
                  input?.toLowerCase()
                )
              }
              size="large"
              placeholder={BOM.handlePlaceholderMsg(
                `${labelConfig.bankName} *`,
                "select"
              )}
              options={[
                { label: "FNB/RMB", value: "FNB/RMB" },
                // { label: "Capitec", value: "Capitec" },
              ]}
              showSearch
            />
          </Form.Item>
        </Col>
        <Col span={24} style={{ padding: "0px 8px" }}>
          <Form.Item
            label={labelConfig.holder}
            name="rcpHolder"
            rules={[
              {
                required: true,
                message: BOM.CheckReqFld(labelConfig.holder),
              },
            ]}
          >
            <Input
              maxLength={40}
              placeholder={BOM.handlePlaceholderMsg(`${labelConfig.holder} *`)}
            />
          </Form.Item>
        </Col>
        <Col span={24} style={{ padding: "0px 8px" }}>
          <Form.Item
            label={labelConfig.accountType}
            name="rcpAccountType"
            rules={[
              {
                required: true,
                message: BOM.CheckReqFld(labelConfig.accountType),
              },
            ]}
          >
            <Select
              filterOption={(input, option) =>
                (option?.label?.toLowerCase() ?? "").includes(
                  input?.toLowerCase()
                )
              }
              size="large"
              placeholder={BOM.handlePlaceholderMsg(
                `${labelConfig.accountType} *`,
                "select"
              )}
              options={[
                { label: "Current", value: "Current" },
                { label: "Saving", value: "Saving" },
              ]}
              showSearch
            />
          </Form.Item>
        </Col>
        <Col span={24} style={{ padding: "0px 8px" }}>
          <Form.Item
            label={labelConfig.accountNumber}
            name="rcpAccountNumber"
            rules={[
              {
                required: true,
                message: BOM.CheckReqFld(labelConfig.accountNumber),
              },
              {
                min: 5,
                message: BOM.CheckReqFld(
                  "Minimum of 5 digits is required"
                ),
              },
              {
                max: 15,
                message: BOM.CheckReqFld(
                  "Maximum of 15 digits is required"
                ),
              },
            ]}
          >
            <Input
              maxLength={40}
              placeholder={BOM.handlePlaceholderMsg(
                `${labelConfig.accountNumber} *`
              )}
            />
          </Form.Item>
        </Col>
        <Col span={24} style={{ padding: "0px 8px" }}>
          <Form.Item
            label={labelConfig.branch}
            name="rcpBranch"
            rules={[
              {
                required: true,
                message: BOM.CheckReqFld(labelConfig.branch),
              },
              {
                min: 3,
                message: BOM.CheckReqFld(
                  "Minimum of 3 digits is required"
                ),
              },
              {
                max: 10,
                message: BOM.CheckReqFld(
                  "Maximum of 10 digits is required"
                ),
              },
            ]}
          >
            <Input
              maxLength={15}
              placeholder={BOM.handlePlaceholderMsg(`${labelConfig.branch} *`)}
            />
          </Form.Item>
        </Col>
      </>
    );
  };

export default BankFields