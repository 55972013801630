import ManageRecipient from "./ManageRecipient";
import NewRecipient from "./NewRecipient";
import UpdateRecipient from "./UpdateRecipient";


export default [
  {
    path: "/manage-recipient",
    component: ManageRecipient,
    isProtected: true,
  },
  {
    path: "/new-recipient",
    component: NewRecipient,
    isProtected: true,
  },
  {
    path: "/update-recipient",
    component: UpdateRecipient,
    isProtected: true,
  },
];
