import ManageTransfer from "./ManageTransfer";
import DetailTransfer from "./DetailTransfer";
import NewTransfer from "./NewTransfer";
import UpdateTransfer from "./UpdateTransfer";
import SearchTransfer from "./SearchTransfer";
// import PaymentResponse from "./PaymentResponse";
// import TrackTransfer from "./TrackTransfer";
// import CurrentTransfer from "./CurrentTransfer";


export default [
  {
    path: "/manage-transfer",
    component: ManageTransfer,
    isProtected: true,
  },
  {
    path: "/detail-transfer",
    component: DetailTransfer,
    isProtected: true,
  },
  {
    path: "/new-transfer",
    component: NewTransfer,
    isProtected: true,
  },
  {
    path: "/update-transfer",
    component: UpdateTransfer,
    isProtected: true,
  },
  {
    path: "/search-transfer",
    component: SearchTransfer,
    isProtected: true,
  },
  // {
  //   path: "/response-transfer/:transferStatus",
  //   component: PaymentResponse,
  //   isProtected: true,
  // },
  // {
  //   path: "/track-transfer",
  //   component: TrackTransfer,
  //   isProtected: true,
  // },
  // {
  //   path: "/current-transfer",
  //   component: CurrentTransfer,
  //   isProtected: true,
  // },
];
