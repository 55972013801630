// import _ from "lodash";
import cnt from "../../services/definedConstant";

const BLOCK_RED_INITIAL_STATE = {
  tempPaymentData: {},
  customerConfig: {},
  currentLocation: {},
};
export const BlockReducer = (state = BLOCK_RED_INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case cnt.TEMP_PAYMENT_DATA:
      return {
        ...state,
        tempPaymentData: payload,
      };
    case cnt.CLEAR_TEMP_PAYMENT_DATA:
      return {
        ...state,
        tempPaymentData: null,
      };
    case cnt.CURRENT_CUSTOMER:
      return {
        ...state,
        customerConfig: payload,
      };
    default:
      return state;
  }
};
