import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, connect } from "react-redux";

//ANT DESIGN IMPORTS
import {
  Layout,
  Menu,
  Button,
  Row,
  Col,
  Typography,
  Form,
  Input,
  FloatButton,
  Card,
} from "antd";
import { CustomerServiceOutlined, WhatsAppOutlined } from "@ant-design/icons";
//PROJECT IMPORTS
import { SignOutAction } from "../../redux/actions/authAction";
import cnt from "../../services/definedConstant";
import api from "../../services/definedApi";
import { BOM } from "../../services/definedMethod";
import { apiConfig, labelConfig, staticConfig } from "./auth.config";
import logo from "../../assets/images/logoColorAnimate.gif";
import { WhatsappFacility } from "../../components/Facility";

const { Title, Text } = Typography;
const { Header, Footer, Content } = Layout;

const Landing = (props) => {
  const { SignOutAction } = props;
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cardDisplayData = [
    {
      label: "Register",
      route: "/sign-up",
    },
    {
      label: "Login",
      route: "/sign-in",
    },
    {
      label: "Check Rates",
      route: "/check-rate",
    },
  ];

  useEffect(() => {
    SignOutAction();
  }, []);

  const handleRedirect = (objParam) => {
    navigate(objParam.route);
  };

  return (
    <>
      <WhatsappFacility />
      <Layout className="layout-default layout-signin">
        <Content className="signin">
          <Row>
            <Col
              className="sign-img"
              xs={{ span: 24, offset: 0 }}
              lg={{ span: 24, offset: 0 }}
              md={{ span: 24 }}
            >
              <img src={logo} alt="" />
            </Col>
          </Row>
          <Row gutter={[24, 0]} justify="space-around">
            {/* <Card className="content-card">
              <Col
                className="sign-img"
                xs={{ span: 24, offset: 0 }}
                lg={{ span: 24, offset: 0 }}
                md={{ span: 24 }}
              >
                <img src={logo} alt="" />
                <Title className="font-regular text-muted" level={5}>
                  {labelConfig.welcome}
                </Title>
                <Form
                  onFinish={onSignIn}
                  onFinishFailed={onFinishFailed}
                  layout="vertical"
                  className="row-col"
                >
                  <Form.Item
                    className="username"
                    label="Username"
                    name="username"
                    rules={[
                      {
                        required: true,
                        message: BOM.CheckReqFld(labelConfig.username),
                      },
                      {
                        min: 3,
                        message: "Minimum username length is 3 characters",
                      },
                    ]}
                  >
                    <Input
                      placeholder={BOM.handlePlaceholderMsg(
                        labelConfig.username
                      )}
                    />
                  </Form.Item>

                  <Form.Item
                    className="username"
                    label="Password"
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: BOM.CheckReqFld(labelConfig.password),
                      },
                    ]}
                  >
                    <Input.Password
                      placeholder={BOM.handlePlaceholderMsg(
                        labelConfig.password
                      )}
                      style={{ padding: "0 10px" }}
                    />
                  </Form.Item>
                  <div className="mb-2">
                    <Link to={"/forgot-password"} className="text-dark">
                      {labelConfig.forgotpassQ}
                    </Link>
                  </div>
                  <Form.Item>
                    <Button
                      htmlType="submit"
                      className="of-primaryBtnColor"
                      loading={isLoading}
                    >
                      {labelConfig.btnLogin}
                    </Button>
                  </Form.Item>
                  <p className="font-semibold text-muted">
                    Don't have an account?{" "}
                    <Link to="/sign-up" className="text-dark font-bold">
                      Sign Up
                    </Link>
                  </p>
                </Form>
              </Col>
            </Card> */}

            {cardDisplayData.map((item, index) => (
              <Col
              key={index}
                style={{ padding: 12 }}
                xs={{ span: 14 }}
                md={{ span: 8 }}
                lg={{ span: 4 }}
                onClick={()=>handleRedirect(item)
                }
                className="landing-card mt-25"
              >
                {/* <Card className="landing-card">
                  <p className="landing-card-text">{item.label}</p>
                </Card> */}
                <p className="landing-card-text">{item.label}</p>
              </Col>
            ))}
          </Row>
        </Content>
        <Footer>
          <p className="copyright">
            {" "}
            Copyright © 2024 GdeG by <a href="#pablo">nAcomoda Team</a>.{" "}
          </p>
        </Footer>
      </Layout>
    </>
  );
};
const mapDispatchToProps = { SignOutAction };
export default connect(null, mapDispatchToProps)(Landing);
