import React, { useState, useEffect, useRef } from "react";
import { connect, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Notiflix from "notiflix";

//ANT DESIGN IMPORTS
import {
  Row,
  Col,
  Card,
  Divider,
  Button,
  Form,
  Input,
  Space,
  Select,
  message,
  Spin,
} from "antd";

//PROJECT IMPORTS
import Main from "../../components/layout/Main";
import { BOM } from "../../services/definedMethod";
import cnt from "../../services/definedConstant";
import { apiConfig, labelConfig } from "./recipient.config";
import appConfig from "../../config/appConfig";
import { BackToPreviousScreen } from "../../components/Facility";
import BankFields from "./payoutMethod/BankFields";
import CryptoFields from "./payoutMethod/CryptoFields";
import MobileFields from "./payoutMethod/MobileFields";

const NewRecipient = (props) => {
  //payment gatway info
  const { paymentGatway } = appConfig;
  const { sandbox, live } = paymentGatway;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [useForm] = Form.useForm();
  const selectedPaymentType = Form.useWatch("paymentTypeId", useForm);

  const { userData } = props;
  const { PROFILE_ID, COUNTRY_ID, session } = userData;
  const [isLoading, setIsLoading] = useState(false);
  const [countryData, setCountryData] = useState([]);
  const [payoutMethodData, setPayoutMethodData] = useState([]);
  const [momoData, setMomoData] = useState([]);
  const [countryPhoneCode, setCountryPhoneCode] = useState("+243");
  const [isCountrySelected, setIsCountrySelected] = useState(false);
  const [payoutMethodDisplay, setPayoutMethodDisplay] = useState("");
  const [criteria, setCriteria]=useState("")

  useEffect(() => {
    getRcpTemplate();
  }, []);
  const getRcpTemplate = () => {
    let body = {
      profileId: PROFILE_ID,
      token: session,
    };
    setIsLoading(true);
    BOM.FetchReqAction(body, apiConfig.GetRecipientTemplate, (err, res) => {
      if (err) {
        const errorMsg = err?.errorData ? err?.errorData[0] : err.message;
        BOM.AlertMsg(cnt.DANGER, errorMsg);
        setIsLoading(false);
      } else {
        // return
        const objList = res.recipientTemplate;
        let tempCountry = [];
        let tempPayoutMethod = [];
        let tempPayoutOrMomo = [];

        objList?.countryOptions
          ?.filter((item) => item.ID !== COUNTRY_ID)
          ?.forEach((element) => {
            tempCountry.push({
              value: element.ID,
              // label: `${element.COUNTRY_NAME} ( ${element.SORTNAME} )`,
              searchLabel: element.COUNTRY_NAME,
              label: (
                <>
                  <img style={{ width: 20 }} src={element.COUNTRY_FLAG} />{" "}
                  {element.COUNTRY_NAME}
                </>
              ),
              phoneCode: element.COUNTRY_PHONE_CODE,
            });
          });

        setCountryData(tempCountry);

        // PAYOUT METHOD
        objList?.payoutMethodOptions?.forEach((element) => {
          tempPayoutMethod.push({
            value: element.PAYMENT_TYPE_ID,
            label: element.PAYMENT_TYPE_NAME,
          });
        });

        setPayoutMethodData(tempPayoutMethod);

        objList?.payoutMomoOptions?.forEach((element) => {
          tempPayoutOrMomo.push({
            value: element.MOMO_ID,
            label: `${element.MOMO_NAME} ( ${element.MOMO_OPERATOR} )`,
          });
        });

        setMomoData(tempPayoutOrMomo);
        //STOP LOAD
        setIsLoading(false);
      }
    });
  };
  const onSelectCountry = (value, objParam) => {
    setIsCountrySelected(true);
    setCountryPhoneCode(objParam.phoneCode);
  };
  const handlePayoutMethod = (_, keyParam) => {
    setCriteria(keyParam?.label)
    switch (keyParam?.label?.toLowerCase()) {
      case "bank":
        return setPayoutMethodDisplay(<BankFields />);
      case "crypto":
        return setPayoutMethodDisplay(<CryptoFields />);
      case "mobile":
        return setPayoutMethodDisplay(<MobileFields momoData={momoData} />);
      default:
        return <></>;
    }
  };
  const onSubmit = (formValues) => {
    // formValues.rcpPhoneNo = `${countryPhoneCode}${formValues.rcpPhoneNo}`;
    formValues.rcpSponsor = PROFILE_ID;
    formValues.criteria = criteria;

    let body = {
      profileId: PROFILE_ID,
      ...formValues,
      token: session,
    };

    // console.log(body);
    // return;
    let confirmMessage =
      "<p>Please double-check that the information you entered is correct.We cannot refund money if it is sent to the wrong recipient!</p>&nbsp;";
    confirmMessage += "<p>By confirming, you agree these terms.</p>";
    Notiflix.Confirm.show(
      "Confirm recipient creation",
      `${confirmMessage}`,
      "Confirm",
      "Cancel",
      function () {
        // Yes
        setIsLoading(true);
        BOM.FetchReqAction(body, apiConfig.NewRecipient, (err, res) => {
          if (err) {
            const errorMsg = err?.errorData ? err?.errorData[0] : err.message;
            BOM.AlertMsg(cnt.DANGER, errorMsg);
            setIsLoading(false);
          } else {
            message.success(res.message,10);
            navigate("/manage-recipient");
            setIsLoading(false);
          }
        });
      },
      function () {
        // No button
      }
    );
  };
  return (
    <Main>
      <Spin spinning={isLoading} size="large">
        <Row gutter={[24, 0]}>
          <Col span={24} md={24} className="mb-24">
            <BackToPreviousScreen />
            <Card className="header-solid h-full">
              <Form
                form={useForm}
                layout="vertical"
                autoComplete="off"
                onFinish={onSubmit}
              >
                <Row>
                  <Col span={24} style={{ padding: "0px 8px" }}></Col>
                  <Divider orientation="left">
                    {labelConfig.recipientPersonalInformation}
                  </Divider>
                  <Col span={24} style={{ padding: "0px 8px" }}>
                    <Form.Item
                      label={labelConfig.firstName}
                      name="rcpFirstname"
                      rules={[
                        {
                          required: true,
                          message: BOM.CheckReqFld(labelConfig.firstName),
                        },
                      ]}
                    >
                      <Input
                        maxLength={40}
                        placeholder={BOM.handlePlaceholderMsg(
                          `${labelConfig.firstName} *`
                        )}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24} style={{ padding: "0px 8px" }}>
                    <Form.Item
                      label={labelConfig.lastName}
                      name="rcpLastname"
                      rules={[
                        {
                          required: true,
                          message: BOM.CheckReqFld(labelConfig.lastName),
                        },
                      ]}
                    >
                      <Input
                        maxLength={40}
                        placeholder={BOM.handlePlaceholderMsg(
                          `${labelConfig.lastName} *`
                        )}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={24} style={{ padding: "0px 8px" }}>
                    <Form.Item
                      label={`${labelConfig.country}`}
                      name="countryId"
                      rules={[
                        {
                          required: true,
                          message: BOM.CheckReqFld(labelConfig.country),
                        },
                      ]}
                    >
                      <Select
                        filterOption={(input, option) =>
                          (option?.searchLabel?.toLowerCase() ?? "").includes(
                            input?.toLowerCase()
                          )
                        }
                        size="large"
                        placeholder={BOM.handlePlaceholderMsg(
                          `${labelConfig.country} *`,
                          "select"
                        )}
                        options={countryData}
                        showSearch
                        onChange={onSelectCountry}
                      />
                    </Form.Item>
                  </Col>
                  {isCountrySelected && (
                    <>
                      <Col span={24} style={{ padding: "0px 8px" }}>
                        <Form.Item
                          label={labelConfig.phoneNo}
                          name="rcpPhoneNo"
                          rules={[
                            {
                              required: true,
                              message: BOM.CheckReqFld(labelConfig.phoneNo),
                            },
                            {
                              min: 9,
                              message: BOM.CheckReqFld(
                                "Minimum of 9 digits is required"
                              ),
                            },
                            {
                              max: 9,
                              message: BOM.CheckReqFld(
                                "Maximum of 9 digits is required"
                              ),
                            },
                          ]}
                        >
                          <Space.Compact>
                            <Input
                              style={{
                                width: "20%",
                              }}
                              value={countryPhoneCode}
                              disabled
                              className="gg-bold-disable-input"
                            />
                            <Input
                              className="inputFieldNumber"
                              maxLength={20}
                              placeholder={BOM.handlePlaceholderMsg(
                                `${labelConfig.phoneNo} *`
                              )}
                            />
                          </Space.Compact>
                        </Form.Item>
                      </Col>
                      <Divider orientation="left">
                        {labelConfig.recipientPayoutMethod}
                      </Divider>

                      <Col span={24} style={{ padding: "0px 8px" }}>
                        <Form.Item
                          label={`${labelConfig.payoutMethod}`}
                          name="paymentTypeId"
                          rules={[
                            {
                              required: true,
                              message: BOM.CheckReqFld(
                                labelConfig.payoutMethod
                              ),
                            },
                          ]}
                        >
                          <Select
                            filterOption={(input, option) =>
                              (option?.label?.toLowerCase() ?? "").includes(
                                input?.toLowerCase()
                              )
                            }
                            size="large"
                            placeholder={BOM.handlePlaceholderMsg(
                              `${labelConfig.payoutMethod} *`,
                              "select"
                            )}
                            onChange={handlePayoutMethod}
                            options={payoutMethodData}
                            showSearch
                          />
                        </Form.Item>
                      </Col>
                    
                      {payoutMethodDisplay}
                    </>
                  )}
                </Row>
                <Form.Item>
                  <Button
                    htmlType="submit"
                    className="of-primaryBtnColor"
                    loading={isLoading}
                  >
                    {labelConfig.submit}
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </Col>
        </Row>
      </Spin>
    </Main>
  );
};

export default NewRecipient;



