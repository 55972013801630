import { useNavigate } from "react-router-dom";
//project imports

import cnt from "../../services/definedConstant";
import { BOM } from "../../services/definedMethod";
import  {store} from "../storeOf"


export const SignOutAction = () => {
  return () => {
    store.dispatch({ type: cnt.SIGN_OUT });
  };
};
