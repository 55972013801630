import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, connect } from "react-redux";

//ANT DESIGN IMPORTS
import {
  Layout,
  Menu,
  Button,
  Row,
  Col,
  Typography,
  Form,
  Input,
  FloatButton,
  Card,
} from "antd";
import { CustomerServiceOutlined, WhatsAppOutlined } from "@ant-design/icons";
//PROJECT IMPORTS
import { SignOutAction } from "../../redux/actions/authAction";
import cnt from "../../services/definedConstant";
import api from "../../services/definedApi";
import { BOM } from "../../services/definedMethod";
import { apiConfig, labelConfig, staticConfig } from "./auth.config";
import logo from "../../assets/images/logoColorAnimate.gif";
import { BackToHomeScreen, WhatsappFacility } from "../../components/Facility";

const { Title } = Typography;
const { Header, Footer, Content } = Layout;

const SignIn = (props) => {
  const { SignOutAction } = props;
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    SignOutAction();
  }, []);

  const onSignIn = (formValues) => {
    const body = {
      ...formValues,
    };

    // console.log(body);
    // return
    setIsLoading(true);
    BOM.SessionLessReqAction(body, apiConfig.Authentication, (err, res) => {
      if (err) {
        const errorMsg = err?.errorData ? err?.errorData[0] : err.message;
        BOM.AlertMsg(cnt.DANGER, errorMsg);
        // BOM.AlertMsg(cnt.DANGER, err.message);
        // message.error(err.message);
        setIsLoading(false);
      } else {
        dispatch({
          type: cnt.SIGN_IN,
          payload: res.userData,
        });

        const { ROLE_SHORT } = res.userData;

        switch (ROLE_SHORT?.toLowerCase()) {
          case "sys":
          case "sad":
          case "adm":
            return navigate("/admin-dashboard");
          case "cus":
            return navigate("/dashboard");
          default:
            BOM.AlertMsg(cnt.DANGER, "You don't have authorization")
            navigate("/");
            break
        }
      }
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <>
      <WhatsappFacility />
      <Layout className="layout-default layout-signin">
        <Content className="signin">
          <BackToHomeScreen />
          <br />
          <Row gutter={[24, 0]} justify="space-around">
            <Card className="content-card">
              <Col
                className="sign-img"
                xs={{ span: 24, offset: 0 }}
                lg={{ span: 24, offset: 0 }}
                md={{ span: 24 }}
              >
                <img src={logo} alt="" />
                {/* <Title className="mb-15">Sign In</Title> */}
                <Title className="font-regular text-muted" level={5}>
                  {labelConfig.welcome}
                </Title>
                <Form
                  onFinish={onSignIn}
                  onFinishFailed={onFinishFailed}
                  layout="vertical"
                  className="row-col"
                >
                  <Form.Item
                    className="username"
                    label="Username"
                    name="username"
                    rules={[
                      {
                        required: true,
                        message: BOM.CheckReqFld(labelConfig.username),
                      },
                      {
                        min: 3,
                        message: "Minimum username length is 3 characters",
                      },
                    ]}
                  >
                    <Input
                      placeholder={BOM.handlePlaceholderMsg(
                        labelConfig.username
                      )}
                    />
                  </Form.Item>

                  <Form.Item
                    className="username"
                    label="Password"
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: BOM.CheckReqFld(labelConfig.password),
                      },
                    ]}
                  >
                    <Input.Password
                      placeholder={BOM.handlePlaceholderMsg(
                        labelConfig.password
                      )}
                      // style={{ padding: "0 10px" }}
                    />
                  </Form.Item>
                  <div className="mb-2">
                    <Link to={"/forgot-password"} className="text-dark">
                      {labelConfig.forgotpassQ}
                    </Link>
                  </div>
                  <Form.Item>
                    <Button
                      htmlType="submit"
                      className="of-primaryBtnColor"
                      loading={isLoading}
                    >
                      {labelConfig.btnLogin}
                    </Button>
                  </Form.Item>
                  <p className="font-semibold text-muted">
                    Don't have an account?{" "}
                    <Link to="/sign-up" className="text-dark font-bold">
                      Sign Up
                    </Link>
                  </p>
                </Form>
              </Col>
            </Card>
            {/* <Col
              className="sign-img"
              style={{ padding: 12 }}
              xs={{ span: 24 }}
              lg={{ span: 12 }}
              md={{ span: 12 }}
            >
              <img src={logo} alt="" />
            </Col> */}
          </Row>
        </Content>
        <Footer>
          <p className="copyright">
            {" "}
            Copyright © 2024 GdeG by <a href="#pablo">nAcomoda Team</a>.{" "}
          </p>
        </Footer>
      </Layout>
    </>
  );
};
const mapDispatchToProps = { SignOutAction };
export default connect(null, mapDispatchToProps)(SignIn);
