import React, { useState, useEffect, useRef } from "react";
import { connect, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

//ANT DESIGN IMPORTS
import {
  Row,
  Col,
  Card,
  Divider,
  Button,
  Form,
  Spin,
  Typography,
  Select,
  Descriptions,
  message,
} from "antd";

//PROJECT IMPORTS
import Main from "../../components/layout/Main";
import { BOM } from "../../services/definedMethod";
import cnt from "../../services/definedConstant";
import { apiConfig, labelConfig } from "./transfer.config";
import appConfig from "../../config/appConfig";
import { AmountFormat, BackToPreviousScreen } from "../../components/Facility";

const NewTransfer = (props) => {
  //payment gatway info
  const { paymentGatway } = appConfig;
  const { sandbox, live } = paymentGatway;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [useForm] = Form.useForm();
  // const selectedAmount = Form.useWatch('amount', useForm);
  const selectedAmount = Form.useWatch(
    (values) => `${values.amount || 0}`,
    useForm
  );
  const { userData } = props;
  const { PROFILE_ID, COUNTRY_ID, session } = userData;
  const [isLoading, setIsLoading] = useState(false);
  const [amountOptions, setAmountOptions] = useState(0);
  const [template, setTemplate] = useState();
  const [feeTransfer, setFeeTransfer] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  // const [totalAmount, setTotalAmount] = useState(0);
  const successUrl = BOM.GetWindowData("origin", "/response-transfer/success");
  const cancelUrl = BOM.GetWindowData("origin", "/response-transfer/cancelled");
  const [showReview, setShowReview] = useState(false);

  const items = [
    {
      key: "1",
      label: "Daily Limit",
      children: `${template?.companyCurrency?.symbol} ${template?.limitAmount}`,
      // children: AmountFormat(template?.limitAmount, template?.companyCurrency?.symbol),
    },
    {
      key: "1",
      label: `Recipient ( ${state?.toCountrySortname} )`,
      children: (
        <>
          {state?.recipientNames}{" "}
          <img style={{ width: 20 }} src={state?.flag} />{" "}
        </>
      ),

      // children: AmountFormat(template?.limitAmount, template?.companyCurrency?.symbol),
    },
  ];

  useEffect(() => {
    // RECIPIENT IS A MUST
    if (!state?.id) return navigate("/manage-recipient");

    getTemplate();
  }, []);

  const getTemplate = () => {
    let body = {
      profileId: PROFILE_ID,
      token: session,
    };
    BOM.FetchReqAction(body, apiConfig.GetTransferTemplate, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err.message);
        setIsLoading(false);
      } else {
        const objDetail = res.transferTemplateDetail;

        if (objDetail?.outstandingOrder?.count > 0) {
          BOM.AlertMsg(
            cnt.WARNING,
            "GdeG only accepts one transfer order at a time. Please pay for or cancel your current transfer."
          );
          navigate("/manage-transfer");
          return;
        }

        setTemplate({
          limitAmount: objDetail.GLOBAL_LIMIT,
          feeRate: objDetail.GLOBAL_FEE_RATE,
          exchangeRate: objDetail.EXCHANGE_RATE,
          transferReferenceCode: objDetail.TRF_CODE,
          companyCurrency: {
            id: objDetail?.companyCurrency?.CUR_ID,
            name: objDetail?.companyCurrency?.CUR_ID,
            code: objDetail?.companyCurrency?.CUR_CODE,
            symbol: objDetail?.companyCurrency?.CUR_SYMBOL,
          },
          localCurrency: {
            id: objDetail?.localCurrency?.CUR_ID,
            name: objDetail?.localCurrency?.CUR_ID,
            code: objDetail?.localCurrency?.CUR_CODE,
            symbol: objDetail?.localCurrency?.CUR_SYMBOL,
          },
          companyBankInfo: objDetail?.companyBankInfo,
        });

        generateAmount(objDetail.GLOBAL_LIMIT);

        // useForm.setFieldsValue({amount:0})
        //STOP LOAD
        setIsLoading(false);
      }
    });
  };
  const generateAmount = (length) => {
    const tempAmount = [];
    for (let index = 10; index <= length; index++) {
      tempAmount.push({
        value: index,
        label: index,
      });
    }
    setAmountOptions(tempAmount);
  };
  const onSelectAmount = (param) => {
    const fee = (param * parseFloat(template?.feeRate)) / 100;

    //display to the UI
    setFeeTransfer(BOM.HandleMathRound(fee));
    setTotalAmount(BOM.HandleMathRound(fee + param));
    setShowReview(param > 0 ? true : false);
  };
  const onSubmit = (formValues) => {
    formValues.transferCode = template.transferReferenceCode;
    formValues.totalAmount = totalAmount;
    formValues.exchangeRate = template.exchangeRate;
    formValues.currencyId = template.companyCurrency.id;
    formValues.fee = feeTransfer;
    formValues.recipientId = state.id;

    let body = {
      profileId: PROFILE_ID,
      ...formValues,
      token: session,
    };

    // console.log(body);
    // return;
    setIsLoading(true);
    BOM.FetchReqAction(body, apiConfig.NewTransfer, (err, res) => {
      if (err) {
        const errorMsg = err?.errorData ? err?.errorData[0] : err.message;
        BOM.AlertMsg(cnt.DANGER, errorMsg);
        setIsLoading(false);
      } else {
        message.success(res.message, 10);
        navigate("/manage-transfer");
        setIsLoading(false);
      }
    });
  };
  return (
    <Main>
      <Spin spinning={isLoading} size="large">
        <Row gutter={[24, 0]}>
          <Col span={24} md={24} className="mb-24">
            <BackToPreviousScreen />
            <Card className="header-solid h-full">
              <Form
                form={useForm}
                layout="vertical"
                autoComplete="off"
                onFinish={onSubmit}
              >
                {/* <Divider orientation="left">
                {labelConfig.generalInformation}
              </Divider> */}

                <div>
                  <Col md={24} xs={24} className="mb-24">
                    <Descriptions bordered items={items} />
                  </Col>
                  <Col md={12} xs={24}>
                    <Form.Item
                      label={`${labelConfig.amount} to send`}
                      name="amount"
                      rules={[
                        {
                          required: true,
                          message: BOM.CheckReqFld(labelConfig.amount),
                        },
                      ]}
                    >
                      <Select
                        placeholder={BOM.handlePlaceholderMsg(
                          `${labelConfig.amount} *`
                        )}
                        options={amountOptions}
                        showSearch
                        onChange={onSelectAmount}
                      />
                    </Form.Item>
                  </Col>
                </div>
                {showReview && (
                  <div>
                    <Divider orientation="left">
                      {labelConfig.reviewTrfOrder}
                    </Divider>
                    <Row gutter={[24, 0]}>
                      <Col
                        xs={24}
                        sm={24}
                        md={12}
                        lg={12}
                        xl={10}
                        className="mb-24"
                      >
                        <Card className="card-billing-info" bordered="false">
                          <div className="col-info">
                            <img
                              className="country-flag"
                              src="https://flagsapi.com/CD/flat/64.png"
                            />
                            {/* <Descriptions title="USD Review"> */}
                            <Descriptions>
                              <Descriptions.Item
                                label="You Pay"
                                span={4}
                                labelStyle={{
                                  color: "var(--of-black)",
                                  fontSize: "1.2rem",
                                }}
                              >
                                {/* {template?.companyCurrency?.symbol}{" "} */}
                                {AmountFormat(
                                  BOM.HandleMathRound(totalAmount),
                                  template?.companyCurrency?.symbol
                                )}
                              </Descriptions.Item>

                              <Descriptions.Item
                                label={`GdeG's Fee ( ${template?.feeRate} % )`}
                                span={4}
                              >
                                {template?.companyCurrency?.symbol}{" "}
                                {BOM.HandleMathRound(feeTransfer)}
                              </Descriptions.Item>
                              <Descriptions.Item label="Rate" span={4}>
                                USD 1 = ZAR {template?.exchangeRate}
                              </Descriptions.Item>
                              <Descriptions.Item
                                labelStyle={{
                                  color: "var(--green)",
                                  fontSize: "1rem",
                                }}
                                contentStyle={{
                                  color: "var(--green)",
                                  fontSize: "1rem",
                                }}
                                label="Recipient Receives"
                                span={4}
                              >
                                {`${
                                  template?.companyCurrency?.symbol
                                } ${BOM.HandleMathRound(selectedAmount)}`}
                              </Descriptions.Item>
                            </Descriptions>
                          </div>
                        </Card>
                      </Col>
                      <Col
                        xs={24}
                        sm={24}
                        md={12}
                        lg={12}
                        xl={10}
                        className="mb-24"
                      >
                        <Card className="card-billing-info" bordered="false">
                          <div className="col-info">
                            <img
                              className="country-flag"
                              src="https://flagsapi.com/ZA/flat/64.png"
                            />
                            <Descriptions>
                              <Descriptions.Item
                                label="You Pay"
                                span={4}
                                labelStyle={{
                                  color: "var(--of-black)",
                                  fontSize: "1.2rem",
                                }}
                              >
                                {AmountFormat(
                                  BOM.HandleMathRound(
                                    totalAmount * template?.exchangeRate
                                  ),
                                  template?.localCurrency?.symbol
                                )}
                              </Descriptions.Item>

                              <Descriptions.Item
                                label={`GdeG's Fee ( ${template?.feeRate} % )`}
                                span={4}
                              >
                                {template?.localCurrency?.symbol}{" "}
                                {BOM.HandleMathRound(
                                  feeTransfer * template?.exchangeRate
                                )}
                              </Descriptions.Item>
                              <Descriptions.Item label="Rate" span={4}>
                                USD 1 = ZAR {template?.exchangeRate}
                              </Descriptions.Item>
                              <Descriptions.Item
                                label="Recipient Receives"
                                labelStyle={{
                                  color: "var(--green)",
                                  fontSize: "1rem",
                                }}
                                contentStyle={{
                                  color: "var(--green)",
                                  fontSize: "1rem",
                                }}
                                span={4}
                              >
                                {`${
                                  template?.localCurrency?.symbol
                                } ${BOM.HandleMathRound(
                                  selectedAmount * template?.exchangeRate
                                )}`}
                              </Descriptions.Item>
                            </Descriptions>
                          </div>
                        </Card>
                      </Col>
                    </Row>

                    <Form.Item>
                      <Button
                        htmlType="submit"
                        className="of-primaryBtnColor"
                        loading={isLoading}
                      >
                        {labelConfig.newTransferBtn}
                      </Button>
                    </Form.Item>
                  </div>
                )}
              </Form>
            </Card>
          </Col>
        </Row>
      </Spin>
    </Main>
  );
};

export default NewTransfer;
