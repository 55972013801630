export default {
  /** ALERT */
  WARNING: "WARNING",
  SUCCESS: "SUCCESS",
  DANGER: "DANGER",
  INFO: "INFO",
  PROCESS: "PROCESS",
  LOAD: "LOAD",
  COPIED: "COPIED",

  /** AUTH */
  SIGN_IN: "SIGN_IN",
  SIGN_OUT: "SIGN_OUT",
  LOCKSCREEN: "LOCKSCREEN",
  SIGN_UP: "SIGN_UP",
  AUTH_BUFFER: "AUTH_BUFFER",

  // BLOCK REDUCER
  CURRENT_TEMPLATE: "CURRENT_TEMPLATE",
  TEMP_PAYMENT_DATA: "TEMP_PAYMENT_DATA",
  CURRENT_CUSTOMER: "CURRENT_CUSTOMER",
  CLEAR_TEMP_PAYMENT_DATA: "CLEAR_TEMP_PAYMENT_DATA",
};
