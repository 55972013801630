import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, connect } from "react-redux";

//ANT DESIGN IMPORTS
import {
  Layout,
  Menu,
  Button,
  Row,
  Col,
  Typography,
  Form,
  Input,
  message,
  Card,
} from "antd";
import {
  LeftCircleOutlined,
  ArrowLeftOutlined
} from "@ant-design/icons";

//PROJECT IMPORTS
import { SignOutAction } from "../../redux/actions/authAction";
import cnt from "../../services/definedConstant";
import { BOM } from "../../services/definedMethod";
import { apiConfig, labelConfig, staticConfig } from "./auth.config";
import logo from "../../assets/images/logoColorAnimate.gif";
import { WhatsappFacility } from "../../components/Facility";

const { Title } = Typography;
const { Header, Footer, Content } = Layout;

const ForgotPassword = (props) => {
  const { SignOutAction } = props;
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    SignOutAction();
  }, []);

  const onSubmit = (formValues) => {
    const body = {
      ...formValues,
    };
    
    // console.log(body);
    // return
    setIsLoading(true);
    BOM.SessionLessReqAction(body, apiConfig.ForgotPassword, (err, res) => {
      if (err) {
        // BOM.AlertMsg(cnt.DANGER, message);
        message.error(err?.errorData ? err?.errorData[0] : err.message);
        setIsLoading(false);
      } else {
        // message.success(res.message);
        BOM.AlertMsg(cnt.SUCCESS, res.message);
        navigate("/sign-in");
      }
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <>
    <WhatsappFacility/>
      <Layout className="layout-default layout-signin">
        <Content className="signin">
          <Row gutter={[24, 0]} justify="space-around">
            <Card
              style={{
                marginTop: "20px",
                width: 400,
                // height:500,
                marginLeft: "auto",
                marginRight: "auto",
                boxShadow: "0px 0px 30px 10px rgba(0,0,0,0.1)",
                borderRadius: 25,
              }}
            >
              <Col
                className="sign-img"
                xs={{ span: 24, offset: 0 }}
                lg={{ span: 24, offset: 0 }}
                md={{ span: 24 }}
              >
                <img src={logo} alt="" />
                {/* <Title className="mb-15">{labelConfig.forgotpass}</Title> */}
                <Title className="font-regular text-dark" level={5}>
                  {labelConfig.forgotpass}
                </Title>
                <Form
                  onFinish={onSubmit}
                  onFinishFailed={onFinishFailed}
                  layout="vertical"
                  className="row-col"
                >
                  <Form.Item
                    className="username"
                    label={labelConfig.email}
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: BOM.CheckReqFld(labelConfig.email),
                      },
                    ]}
                  >
                    <Input placeholder="Email" />
                  </Form.Item>
                  <Form.Item>
                    <Button
                      htmlType="submit"
                      className="of-primaryBtnColor"
                      loading={isLoading}
                    >
                      {labelConfig.submitBtn}
                    </Button>
                  </Form.Item>
                  <p className="font-semibold text-muted">
                      <ArrowLeftOutlined/> &nbsp;
                    <Link to="/sign-in" className="text-dark font-bold">
                    Back to Sign In
                    </Link>
                  </p>
                </Form>
              </Col>
            </Card>
          </Row>
        </Content>
        <Footer>
          <p className="copyright">
            {" "}
            Copyright © 2024 GdeG by <a href="#pablo">nAcomoda Team</a>.{" "}
          </p>
        </Footer>
      </Layout>
    </>
  );
};
const mapDispatchToProps = { SignOutAction };
export default connect(null, mapDispatchToProps)(ForgotPassword);
