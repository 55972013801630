import SignIn from "./SignIn";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";
import ChangePassword from "./ChangePassword";
import SignUp from "./SignUp";
import Landing from "./Landing";

export default [
  
  {
    path: "/",
    component: Landing,
    isProtected: false,
  },
  {
    path: "/sign-in",
    component: SignIn,
    isProtected: false,
  },
  {
    path: "/forgot-password",
    component: ForgotPassword,
    isProtected: false,
  },
  {
    path: "/reset-password/:token",
    component: ResetPassword,
    isProtected: false,
  },
  {
    path: "/change-password",
    component: ChangePassword,
    isProtected: true,
  },
  {
    path: "/sign-up",
    component: SignUp,
    isProtected: false,
  },
];
