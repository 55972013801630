import Notiflix from "notiflix";
import cnt from "./definedConstant";
import appLabel from "../config/appLabel";
// import { store } from "../redux/storeOf";
import axios from "axios";
import appConfig from "../config/appConfig";
import { history } from "../history";

/** BM (Block Of Methods) */
export const BOM = {
  IsEmpty: (obj) => {
    for (const prop in obj) {
      if (Object.hasOwn(obj, prop)) {
        return false;
      }
    }

    return true;
  },
  GetCurrentLocation:()=>{
    fetch('https://api.ipregistry.co/?key=tryout')
    .then(function (response) {
        return response.json();
    })
    .then(function (payload) {
        console.log(payload.location.country.name + ', ' + payload.location.city);
    });
  },
  Greet: () => {
    var today = new Date();
    var curHr = today.getHours();

    if (curHr >= 0 && curHr < 6) {
      return "Good day,";
    } else if (curHr >= 6 && curHr < 12) {
      return "Good morning,";
    } else if (curHr >= 12 && curHr < 17) {
      return "Good afternoon,";
    } else {
      return "Good evening,";
    }
  },
  HandleMathRound: (numParam) => {
    return Math.round(numParam * 100) / 100;
  },
  HandleToFixed: (numParam, precision = null) => {
    return parseFloat(numParam).toFixed(precision ? precision : 4);
  },
  FormatArrayDate: (arrayParam) => {
    if (!arrayParam) return;

    let year = arrayParam[0];
    let month = arrayParam[1];
    let day = arrayParam[2];

    if (day < 10) {
      day = "0" + day;
    }
    if (month < 10) {
      month = "0" + month;
    }

    return (year + "-" + month + "-" + day).toString();
  },
  handleReqFldMsg: (key) => {
    return key + " is required.";
  },
  handlePlaceholderMsg: (key, keyType = null) => {
    switch (keyType) {
      case "select":
        return `${appLabel.pleaseSelect} ${key}`;
      default:
        return `${appLabel.pleaseEnter} ${key}`;
    }
  },
  handleSuccessMsg: (key) => {
    return `${key} was successfully updated`;
  },
  IsProductionUrl: (key) => {
    switch (key) {
      case true:
        return appConfig.baseURL.prod;
      default:
        return appConfig.baseURL.dev;
    }
  },
  baseUrl: (apiParam) => {
    return `${BOM.IsProductionUrl(appConfig.isProduction)}/${apiParam}`;
  },
  // gatewayPaymt: (parm) => {
  //   switch (parm.toLowerCase()) {
  //     case "sandbox":
  //       return paymentGatwayURL.sandbox;
  //     case "live":
  //       return paymentGatwayURL.live;
  //   }
  // },

  RegExValidation: (key) => {
    switch (key.toLowerCase()) {
      case "only-number":
        return /^[0-9]+$/;
      case "email":
        return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      case "password":
        // return /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/
        return /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!?@#$%^&*/{};:,<>`])[a-zA-Z0-9!?@#$%^&*/{};:,<>`]{6,16}$/;
      default:
        return null;
    }
  },
  //end
  FetchReqAction: async (body, apiUrl, callback) => {
    const { headers } = appConfig.apiSettings;
    headers.Authorization = body.session;

    // const method = body?.method ? body?.method : "GET";

    delete body?.session;
    delete body?.method;

    var config = {
      method: "post",
      url: apiUrl,
      headers,
      data: body,
    };

    // console.log(config);

    axios(config)
      .then(function (response) {
        let res = response?.data;
        callback(null, res);
      })
      .catch(function (error) {
        if (error?.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          if (
            error?.response?.data?.errorData &&
            error?.response?.data?.errorData[0]?.toLowerCase() ===
              "session is required"
          ) {
            history.push("/sign-in");
            window.location.reload();
            return;
          }
          callback(error.response.data);
          console.log(
            "catch 1 section: The request was made and the server responded with a status code"
          );
          console.log(error?.response?.data?.message);
        } else if (error.request) {
          // The request was made but no response was received
          // history.push("/sign-in");
          // window.location.reload();
          console.log(
            "catch 2 section: The request was made but no response was received"
          );
          BOM.AlertMsg(cnt.FAILURE, appLabel.smthWentWrong);
        } else {
          // Something happened in setting up the request that triggered an Error
          // BOM.AlertMsg(cnt.FAILURE, appLabel.smthWentWrong);
          // console.log(
          //   "catch 3 section: Something happened in setting up the request that triggered an Error"
          // );
        }
      });
    // .catch(function (error) {
    //   console.log(error);

    //   if (error?.response) {
    //     const { data } = error?.response;
    //     callback(data, null);
    //   }
    //   BOM.AlertMsg(cnt.DANGER, appLabel.smthWentWrong);
    // });
  },
  SessionLessReqAction: async (body, apiUrl, callback) => {
    const { headers } = appConfig.apiSettings;

    // console.log(headers);

    var config = {
      method: "post",
      url: apiUrl,
      headers,
      data: body,
    };

    axios(config)
      .then(function (response) {
        let res = response?.data;
        // console.log(response);
        callback(null, res);
      })
      .catch(function (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          callback(error.response.data);
          console.log(
            "catch 1 section: The request was made and the server responded with a status code"
          );
          console.log(error?.response?.data?.message);
        } else if (error.request) {
          // The request was made but no response was received
          // history.push("/sign-in");
          // window.location.reload();
          console.log(
            "catch 2 section: The request was made but no response was received"
          );
          BOM.AlertMsg(cnt.FAILURE, appLabel.smthWentWrong);
        } else {
          // Something happened in setting up the request that triggered an Error
          // BOM.AlertMsg(cnt.FAILURE, appLabel.smthWentWrong);
          // console.log(
          //   "catch 3 section: Something happened in setting up the request that triggered an Error"
          // );
        }
      });
    // .catch(function (error) {
    //   // console.log(error.response);
    //   if (error?.response) {
    //     callback(error?.response?.data, null);
    //   }
    //   BOM.AlertMsg(cnt.DANGER, appLabel.smthWentWrong);
    //   // callback(appLabel.smthWentWrong)
    // });
  },
  AlertMsg: (type, message) => {
    Notiflix.Loading.remove();
    try {
      switch (type) {
        case cnt.WARNING:
          return Notiflix.Report.warning("WARNING", message, "OK");
        case cnt.SUCCESS:
          return Notiflix.Report.success("SUCCESS", message, "OK");
        case cnt.DANGER:
          return Notiflix.Report.failure("ERROR", message, "OK");
        case cnt.INFO:
          return Notiflix.Report.info("INFO", message, "OK");
        case cnt.COPIED:
          return Notiflix.Report.success("COPIED", message, "OK");
        default:
          return Notiflix.Report.warning("WARNING", message, "OK");
      }
    } catch (error) {
      console.log("error wow: ", error);
    }
  },
  NotifyAlert: (type, message) => {
    Notiflix.Loading.remove();
    try {
      switch (type) {
        case cnt.WARNING:
          return Notiflix.Notify.warning(message);
        case cnt.SUCCESS:
          return Notiflix.Notify.success(message);
        case cnt.DANGER:
          return Notiflix.Notify.failure(message);
        case cnt.INFO:
          return Notiflix.Notify.info(message);
        case cnt.COPIED:
          return Notiflix.Notify.success(message);
        default:
          return Notiflix.Notify.warning(message);
      }
    } catch (error) {
      console.log("error wow: ", error);
    }
  },
  LoadAlert: (type, message) => {
    try {
      switch (type.toLowerCase()) {
        case cnt.PROCESS:
          return Notiflix.Loading.dots(message + "...");
        case cnt.LOAD:
          return Notiflix.Loading.dots(message + "...");
        default:
          return Notiflix.Loading.dots(message + "...");
      }
    } catch (error) {
      console.log("error on loadingAlert func: ", error);
    }
  },
  GetBrowserName: () => {
    if (
      (navigator.userAgent.indexOf("Opera") ||
        navigator.userAgent.indexOf("OPR")) !== -1
    ) {
      return "Opera";
    } else if (navigator.userAgent.indexOf("Chrome") !== -1) {
      return "Chrome";
    } else if (navigator.userAgent.indexOf("Safari") !== -1) {
      return "Safari";
    } else if (navigator.userAgent.indexOf("Firefox") !== -1) {
      return "Firefox";
    } else if (
      navigator.userAgent.indexOf("MSIE") !== -1 ||
      !document.documentMode === true
    ) {
      return "Internet Explorer";
    } else {
      return "Not sure!";
    }
  },
  GenerateTimestamp: () => {
    // get uniq time for a document sent;
    var now = new Date();
    var timestamp = now.getFullYear().toString();
    timestamp += (now.getMonth() + 1).toString();
    timestamp += now.getDate().toString() + "";
    timestamp += now.getHours().toString();
    timestamp += now.getMinutes().toString();
    timestamp += now.getSeconds().toString();
    timestamp += now.getMilliseconds().toString();
    return timestamp;
  },
  GetUnique: (arr, comp) => {
    // store the comparison  values in array
    const unique = arr
      .map((e) => e[comp])

      // store the indexes of the unique objects
      .map((e, i, final) => final.lastIndexOf(e) === i && i)

      // eliminate the false indexes & return unique objects
      .filter((e) => arr[e])
      .map((e) => arr[e]);

    return unique;
  },
  RmvAccentOnly: (str_param) => {
    // str_param.replace(/\s+/g, " ");
    let mystr = str_param.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    return mystr; //.replace(/'/g, "A");
  },
  GetWindowData: (key, option = "") => {
    switch (key.toLowerCase()) {
      case "origin":
        return window.location.origin + option;
      case "host":
        return window.location.host;
      case "hostname":
        return window.location.hostname;
      case "href":
        return window.location.href;
      case "pathname":
        return window.location.pathname;
      case "port":
        return window.location.port;
      case "protocol":
        return window.location.protocol;
      default:
        return window.location.origin;
    }
  },
  Uwfirst: (str) => {
    return str.toLowerCase().replace(/^\w|\s\w/g, function (letter) {
      return letter.toUpperCase();
    });
  },

  GlobalApproveRejectAction: (body, reqUrl, redirectUrl, label, action) => {
    Notiflix.Confirm.show(
      "Are you sure?",
      action + label,
      "Yes",
      "No",
      function () {
        // Yes
        BOM.LoadAlert(cnt.LOAD, "Processing");
        BOM.FetchReqAction(body, reqUrl, (err, res) => {
          if (err) {
            BOM.AlertMsg(cnt.DANGER, err.message || err.error);
          } else {
            BOM.AlertMsg(cnt.SUCCESS, res.message);
            // history.push(redirectUrl);
          }
        });
      },
      function () {
        // No button
      }
    );
  },
  ApproveRejectWithReload: (body, reqUrl, label, action) => {
    Notiflix.Confirm.show(
      action + label,
      "Are you sure?",
      "Yes",
      "No",
      function () {
        // Yes
        BOM.LoadAlert(cnt.LOAD, "Processing");
        BOM.SendReqActWithReload(body, reqUrl);
      },
      function () {
        // No button
      }
    );
  },
  LoadTimeout: () => {
    Notiflix.Loading.remove();
  },
  SystemTimeout: (param) => {
    return setTimeout(() => {
      BOM.LoadTimeout();
      console.log(`timeout occured on: ${param.toUpperCase()} request API`);
      Notiflix.Report.warning(
        "WARNING",
        "The server encountered something unexpected that didn't allow it to complete the request. We apologize.",
        "OK"
      );
    }, 60000);
  },
  CurrentDate: () => {
    let today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1;
    let yyyy = today.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    return (yyyy + "-" + mm + "-" + dd).toString();
  },
  handleFileRead: async (event, setState_param) => {
    const file = event.target.files[0];
    if (!file) {
      // if file is empty
      setState_param("");
      return;
    }

    const base64 = await BOM.convertBase64(file);
    let strB64 = base64.split("base64,")[1]; //.replace(/^data:image\/[a-z]+;base64,/, "");
    setState_param(strB64);
    console.log(strB64);
  },
  convertBase64: (file) => {
    if (!file) {
      return;
    }
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  },
  DownloadAction: (base64File, fileName) => {
    fileName = BOM.ReplaceSpace(fileName, "_");
    let fileType = BOM.DetectMimeType(base64File);

    const source = "data:" + fileType + ";base64," + base64File;

    const downloadLink = document.createElement("a");

    downloadLink.href = source;
    downloadLink.download = fileName.replace(/ /g, ""); // rename your file
    downloadLink.click();
  },
  DetectMimeType: (b64) => {
    let signatures = {
      JVBERi0: "application/pdf",
      iVBORw0KGgo: "image/png",
      "/9j/4AAQSkZJRgABAQ": "image/jpeg",
    };
    for (var s in signatures) {
      if (b64.indexOf(s) === 0) {
        return signatures[s];
      }
    }
  },
  ReplaceSpace: (str, character) => {
    return str.replace(/ /g, character);
  },
  ReformatDate: (dateParam) => {
    dateParam = new Date(dateParam);
    let dd = dateParam.getDate();
    let mm = dateParam.getMonth() + 1;
    let yyyy = dateParam.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    return (yyyy + "-" + mm + "-" + dd).toString();
  },
  addDaysInDate: (dateParam, param = 0) => {
    const aDay = 60 * 60 * 24 * 1000; // seconds * minutes * hours * milliseconds = 1 day
    dateParam = new Date(dateParam.getTime() + aDay * param);
    let dd = dateParam.getDate();
    let mm = dateParam.getMonth() + 1;
    let yyyy = dateParam.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    return (yyyy + "-" + mm + "-" + dd).toString();
  },
  DateTime: (myDate, keyParam) => {
    myDate = new Date(myDate);

    let daysList = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    let monthsList = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    let date = myDate.getDate();
    let month = monthsList[myDate.getMonth()];
    let year = myDate.getFullYear();
    let day = daysList[myDate.getDay()];

    let today = `${day} ${date} ${month} ${year}`;
    let onlyDate = `${date} ${month} ${year}`;

    let amOrPm;
    let twelveHours = function () {
      if (myDate.getHours() > 12) {
        amOrPm = "PM";
        let twentyFourHourTime = myDate.getHours();
        let conversion = twentyFourHourTime - 12;
        return `${conversion}`;
      } else {
        amOrPm = "AM";
        return `${myDate.getHours()}`;
      }
    };
    let hours = twelveHours();
    let minutes = myDate.getMinutes();
    let second = myDate.getSeconds().toString();

    let currentTime = `${hours}:${minutes}:${second} ${amOrPm.toLowerCase()}`;

    // console.log(today + " " + currentTime);

    switch (keyParam) {
      case "only-date":
        return onlyDate;
      case "date-time":
        return currentTime + " - " + today;

      default:
        return today;
    }
  },
  ReloadComponent: () => {
    window.location.reload(false);
  },
  ValidateNaNValue: (valueParam, labelParam) => {
    valueParam = parseFloat(valueParam);
    let isNaNVal = false;

    if (Number.isNaN(valueParam)) {
      BOM.AlertMsg(cnt.WARNING, labelParam + " is not a valid number.");
      isNaNVal = true;
    }

    if (isNaNVal) return 1;
    else return 0;
  },
  CheckReqFld: (key) => {
    return key + appLabel.isRequired;
  },

  MaskingString: (str, start, end) => {
    if (
      !str ||
      start < 0 ||
      start >= str.length ||
      end < 0 ||
      end > str.length ||
      start >= end
    ) {
      return str;
    }
    const maskLength = end - start;
    const maskedStr =
      str.substring(0, start) + "*".repeat(maskLength) + str.substring(end);
    return maskedStr;
  },
  OnError: (errors) => {
    BOM.AlertMsg(cnt.DANGER, appLabel.fldWithAsteRq);
    console.log(errors);
  },
  formatNumber: (num, precision = 2) => {
    const map = [
      { suffix: "T", threshold: 1e12 },
      { suffix: "B", threshold: 1e9 },
      { suffix: "M", threshold: 1e6 },
      { suffix: "K", threshold: 1e3 },
      { suffix: "", threshold: 1 },
    ];

    const found = map.find((x) => Math.abs(num) >= x.threshold);
    if (found) {
      const formatted =
        (num / found.threshold).toFixed(precision) + found.suffix;
      return formatted;
    }

    return num;
  },
};

/** initialise notiflix pop up */
export const notifInitialization = () => {
  Notiflix.Confirm.init({
    className: "notiflix-confirm",
    width: "300px",
    zindex: 4003,
    position: "center",
    distance: "10px",
    backgroundColor: "#f8f8f8",
    borderRadius: "25px",
    backOverlay: true,
    backOverlayColor: "rgba(0,0,0,0.5)",
    rtl: false,
    fontFamily: "Quicksand",
    cssAnimation: true,
    cssAnimationDuration: 300,
    cssAnimationStyle: "fade",
    plainText: true,
    titleColor: "#FF4D00",
    titleFontSize: "16px",
    titleMaxLength: 34,
    messageColor: "#1e1e1e",
    messageFontSize: "14px",
    messageMaxLength: 200,
    buttonsFontSize: "15px",
    buttonsMaxLength: 34,
    okButtonColor: "#f8f8f8",
    okButtonBackground: "#FF4D00",
    cancelButtonColor: "#f8f8f8",
    cancelButtonBackground: "#a9a9a9",
  });
};
