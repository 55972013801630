import React from "react";
import { Route, Navigate } from "react-router-dom";
import { connect } from "react-redux";

const RouteGuard = (props) => {
  const { component: Component, userData, ...rest } = props;
  const { session } = userData;

  function hasJWT() {
    return session ? true : false;
  }

  return (
    <>
      {hasJWT() ? (
        <Component {...props} />
      ) : (
        <Navigate to={{ pathname: "/" }} />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  userData: state.AuthenticationData.userData,
});

export default connect(mapStateToProps, null)(RouteGuard);
