import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, connect } from "react-redux";

//ANT DESIGN IMPORTS
import {
  Layout,
  Divider,
  Button,
  Row,
  Col,
  Typography,
  Form,
  Input,
  message,
  Card,
  Select,
} from "antd";
//PROJECT IMPORTS
import { SignOutAction } from "../../redux/actions/authAction";
import cnt from "../../services/definedConstant";
import api from "../../services/definedApi";
import { BOM } from "../../services/definedMethod";
import { apiConfig, labelConfig, staticConfig } from "./auth.config";
import logo from "../../assets/images/logoColorAnimate.gif";
import { BackToHomeScreen, WhatsappFacility } from "../../components/Facility";

const { Title } = Typography;
const { Header, Footer, Content } = Layout;

const SignUp = (props) => {
  const { SignOutAction } = props;
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    SignOutAction();
  }, []);

  const onSubmit = (formValues) => {
    formValues.roleId=4; //this will always be 4 for it is customer
    formValues.title = formValues.gender.toLowerCase() === 'male'?'mr':'ms'
    const body = {
      ...formValues,
    };

    // console.log(body);
    // return
    setIsLoading(true);
    BOM.SessionLessReqAction(body, apiConfig.CreateProfile, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err?.errorData ? err?.errorData[0] : err.message);
        setIsLoading(false);
      } else {
        BOM.AlertMsg(cnt.SUCCESS, res.message);
        navigate("/sign-in");
      }
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const validateMessages = {
    required: "${label} is required!",
    types: {
      email: "${label} is not a valid email!",
      number: "${label} is not a valid number!",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };

  return (
    <>
    <WhatsappFacility/>
      <Layout className="layout-default layout-signin">
        <Content className="signin">
        <BackToHomeScreen/>
          <Row gutter={[24, 0]} justify="space-around">
            <Card
              style={{
                marginTop: "20px",
                width: 500,
                height: 600,
                marginLeft: "auto",
                marginRight: "auto",
                boxShadow: "0px 0px 30px 10px rgba(0,0,0,0.1)",
                borderRadius: 25,
                overflow: "auto",
              }}
            >
              <Col
                className="sign-img"
                xs={{ span: 24, offset: 0 }}
                lg={{ span: 24, offset: 0 }}
                md={{ span: 24 }}
              >
                <img src={logo} alt="" />
                {/* <Title className="mb-15">Sign In</Title> */}
                <Title className="font-regular text-muted" level={5}>
                  {labelConfig.profileCreation}
                </Title>
                <Divider orientation="right" orientationMargin="0">
                  {labelConfig.personalInformation}
                </Divider>
                <Form
                  validateMessages={validateMessages}
                  onFinish={onSubmit}
                  onFinishFailed={onFinishFailed}
                  layout="vertical"
                  className="row-col"
                >
                  <Form.Item
                    className="username"
                    label="Username"
                    name="username"
                    rules={[
                      {
                        required: true,
                        message: BOM.CheckReqFld(labelConfig.username),
                      },
                      {
                        min: 3,
                        message: "Minimum username length is 3 characters",
                      },
                    ]}
                  >
                    <Input
                      placeholder={BOM.handlePlaceholderMsg(
                        labelConfig.username
                      )}
                    />
                  </Form.Item>
                  <Form.Item
                    className="username"
                    label={labelConfig.lastname}
                    name="lastname"
                    rules={[
                      {
                        required: true,
                        message: BOM.CheckReqFld(labelConfig.lastname),
                      },
                      {
                        min: 3,
                        message: "Minimum username length is 3 characters",
                      },
                    ]}
                  >
                    <Input
                      placeholder={BOM.handlePlaceholderMsg(
                        labelConfig.lastname
                      )}
                    />
                  </Form.Item>
                  <Form.Item
                    className="username"
                    label={labelConfig.firstname}
                    name="firstname"
                    rules={[
                      {
                        required: true,
                        message: BOM.CheckReqFld(labelConfig.firstname),
                      },
                      {
                        min: 3,
                        message: "Minimum username length is 3 characters",
                      },
                    ]}
                  >
                    <Input
                      placeholder={BOM.handlePlaceholderMsg(
                        labelConfig.firstname
                      )}
                    />
                  </Form.Item>
                  <Form.Item
                    className="username"
                    label={labelConfig.gender}
                    name="gender"
                    rules={[
                      {
                        required: true,
                        message: BOM.CheckReqFld(labelConfig.gender),
                      },
                      {
                        min: 3,
                        message: "Minimum username length is 3 characters",
                      },
                    ]}
                  >
                    <Select
                      placeholder={BOM.handlePlaceholderMsg(
                        labelConfig.gender,
                        "select"
                      )}
                      options={staticConfig.genderData}
                    />
                  </Form.Item>
                  <Divider orientation="right" orientationMargin="0">
                    {labelConfig.contactInformation}
                  </Divider>
                  <Form.Item
                    className="username"
                    label={labelConfig.email}
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: BOM.CheckReqFld(labelConfig.email),
                      },
                      {
                        type: "email",
                      },
                    ]}
                  >
                    <Input
                      placeholder={BOM.handlePlaceholderMsg(labelConfig.email)}
                    />
                  </Form.Item>
                  <Form.Item
                    className="username"
                    label={labelConfig.phone}
                    name="phone"
                    rules={[
                      {
                        required: true,
                        message: BOM.CheckReqFld(labelConfig.phone),
                      },
                      // {
                      //   type: "number",
                      //   min: 10,
                      //   max: 10,
                      // },
                    ]}
                  >
                    <Input
                      placeholder={BOM.handlePlaceholderMsg(labelConfig.phone)}
                    />
                  </Form.Item>
                  <Form.Item>
                    <Button
                      htmlType="submit"
                      className="of-primaryBtnColor"
                      loading={isLoading}
                    >
                      {labelConfig.btnRegister}
                    </Button>
                  </Form.Item>
                  <p className="font-semibold text-muted">
                    Already have an account?{" "}
                    <Link to="/sign-in" className="text-dark font-bold">
                      Sign In
                    </Link>
                  </p>
                </Form>
              </Col>
            </Card>
            {/* <Col
              className="sign-img"
              style={{ padding: 12 }}
              xs={{ span: 24 }}
              lg={{ span: 12 }}
              md={{ span: 12 }}
            >
              <img src={logo} alt="" />
            </Col> */}
          </Row>
        </Content>
        <Footer>
          <p className="copyright">
            {" "}
            Copyright © 2024 GdeG by <a href="#pablo">nAcomoda Team</a>.{" "}
          </p>
        </Footer>
      </Layout>
    </>
  );
};
const mapDispatchToProps = { SignOutAction };
export default connect(null, mapDispatchToProps)(SignUp);
