import { Link } from "react-router-dom";
import { BOM } from "../../services/definedMethod";

/*!*******************************************************************************!
  !*** API CONFIG                                                              ***!
  \*******************************************************************************/
export const apiConfig = {
  Authentication: BOM.baseUrl("login"),
  ChangePassword: BOM.baseUrl("changePassword"),
  ForgotPassword: BOM.baseUrl("forgotPassword"),
  ResetPassword: BOM.baseUrl("resetPassword"),
  CreateProfile: BOM.baseUrl("createProfile"),
};

/*!*******************************************************************************!
  !*** AUTH LABEL CONFIG                                                       ***!
  \*******************************************************************************/
export const labelConfig = {
  username: "Username",
  lastname: "Last Name",
  firstname: "First Name",
  email: "Email",
  phone: "Phone",
  gender: "Gender",
  dob: "Date of Birth",
  title: "Title",
  password: "Password",
  email: "Email",
  btnLogin: "SIGN IN",
  btnRegister: "SIGN UP",
  submitBtn: "SUBMIT",
  forgotpass: "Forgot Password",
  forgotpassQ: "Forgot Password?",
  createAccount: "Register Now",
  currentPassword: "Current Password",
  newPassword: "New Password",
  currentPassword: "Current Password",
  confirmPassword: "Confirm Password",
  resetPassword: "Reset Password",
  changePwd: "Change Password",
  changePasswordInfo: "Change Password Information",
  welcome: "Welcome Back!",
  profileCreation: "Profile Creation",
  male: "Male",
  female: "Female",
  personalInformation: "Personal Information",
  contactInformation: "Contact Information",

};

/*!*******************************************************************************!
    !*** AUTH STATIC CONFIG                                                      ***!
    \*******************************************************************************/
export const staticConfig = {
  genderData: [
    { label: labelConfig.male, value: labelConfig.male },
    { label: labelConfig.female, value: labelConfig.female },
  ],
};
