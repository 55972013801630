import CheckRate from "./exchangeRate/CheckRate";


export default [
  {
    path: "/check-rate",
    component: CheckRate,
    isProtected: false,
  },
];
