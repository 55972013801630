import React, { useState, useEffect} from "react";
import {useNavigate } from "react-router-dom";

import {
  Card,
  Col,
  Row,
  Typography,
  Tooltip,
  Progress,
  Upload,
  message,
  Button,
  Timeline,
  Radio,
} from "antd";
import {
  ToTopOutlined,
  MenuUnfoldOutlined,
  RightOutlined,
  TransactionOutlined,
  SendOutlined,
  GatewayOutlined,
  UsergroupAddOutlined 
} from "@ant-design/icons";
import Paragraph from "antd/lib/typography/Paragraph";

import Echart from "../../components/chart/EChart";
import LineChart from "../../components/chart/LineChart";
import Main from "../../components/layout/Main";
import {BOM} from "../../services/definedMethod";

function AdminDashboard(props) {
  const { userData } = props;
  const { USER_FIRSTNAME, USER_LASTNAME,isAdmin } = userData;
  const { Title, Text } = Typography;
  const fullname = `${USER_FIRSTNAME} ${USER_LASTNAME}`
  const navigate = useNavigate();

  const count = [
    {
      subtitle: "Customer",
      title: "Transfers",
      icon: TransactionOutlined,
      link: "admin-manage-transfer",
    },
    {
      subtitle: "All",
      title: "Users",
      icon: UsergroupAddOutlined ,
      link: "manage-profile",
    },
    {
      subtitle: "My",
      title: "Transfer Histories",
      icon: TransactionOutlined,
      link: "manage-transfer",
    },
  ];

  useEffect(() => {
    if (!isAdmin) return navigate("/");
  }, []);
  const handleRedirect = (objParam) => {
    navigate(objParam.route);
  };
  return (
    <Main>
      <div className="layout-content">
      <Row style={{ marginBottom: 25, marginTop: 5 }}>
        <Title level={4} style={{ margin: 0 }}>
          {BOM.Greet()} {fullname}
        </Title>
      </Row>
        <Row className="rowgap-vbox" gutter={[24, 0]}>
          {count.map((item, index) => (
            <Col
              key={index}
              xs={24}
              sm={24}
              md={8}
              // lg={6}
              // xl={6}
              className="mb-24"
            >
              <Card bordered={false} className="criclebox"  onClick={() => navigate(`/${item.link}`)}>
                <div className="number">
                  <Row align="middle" gutter={[24, 0]}>
                    <Col xs={18}>
                      <span>{item.subtitle}</span>
                      <Title level={4}>
                        {item.title} <small className={item.bnb}>{item.persent}</small>
                      </Title>
                    </Col>
                    <Col xs={6}>
                      <div className="icon-box"><item.icon/></div>
                    </Col>
                  </Row>
                </div>
              </Card>
            </Col>
          ))}
        </Row>
      </div>
    </Main>
  );
}

export default AdminDashboard;
