import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

//ANT DESIGN IMPORTS
import { FloatButton, Button, Row,Typography,Statistic} from "antd";
import { WhatsAppOutlined,LeftOutlined } from "@ant-design/icons";

// PROJECT IMPORTS
import { BOM } from "../services/definedMethod";
import cnt from "../services/definedConstant";
import definedApi from "../services/definedApi";
const { Title,Text } = Typography;

export const WhatsappFacility = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [companyTemplateDetail, setCompanyTemplateDetail] = useState({});
  useEffect(() => {
    getCompanyTemplate();
  }, []);

  const getCompanyTemplate = () => {
    let body = {};
    setIsLoading(true);
    BOM.FetchReqAction(body, definedApi.GetCompanyTemplate, (err, res) => {
      if (err) {
        const errorMsg = err?.errorData ? err?.errorData[0] : err.message;
        BOM.AlertMsg(cnt.DANGER, errorMsg);
        setIsLoading(false);
      } else {
        const objDetail = res.companyTemplateDetail;
        setCompanyTemplateDetail(objDetail);
        //STOP LOAD
        setIsLoading(false);
      }
    });
  };
  return (
    <>
      {!isLoading && (
        <FloatButton
          tooltip={<div>Chat with Us</div>}
          href={`https://api.whatsapp.com/send?phone=${companyTemplateDetail.CO_PHONE}`}
          badge={{
            count: 1,
            color: "var(--whatsAppColor)",
          }}
          icon={
            <WhatsAppOutlined
              style={{
                color: "var(--whatsAppColor)",
                fontSize: 20,
                fontWeight: "bold",
              }}
            />
          }
        />
      )}
    </>
  );
};

export const BackToHomeScreen = () => {
  const navigate = useNavigate();

  return (
  <Row
    style={{
      alignContent: "center",
      width: "8%",
    }}
  >
    <Button
      icon={<LeftOutlined />}
      type="text"
      style={{
        marginTop: "auto",
        marginBottom: "auto",
      }}
      onClick={()=>navigate("/landing")}
    >
      <Text style={{ marginTop: "auto", marginBottom: "auto", fontSize: 15 }}>
        Back
      </Text>
    </Button>
  </Row>
)};
export const BackToPreviousScreen = () => {
  const navigate = useNavigate();

  return (
  <Row
    style={{
      alignContent: "center",
      width: "8%",
    }}
  >
    <Button
      icon={<LeftOutlined />}
      type="link"
      style={{
        marginTop: "auto",
        marginBottom: "auto",
        color:'var(--primaryColor)'
      }}
      onClick={()=>navigate(-1)}
    >
      <Text style={{ marginTop: "auto", marginBottom: "auto", fontSize: 15,color:'var(--primaryColor)' }}>
        Back
      </Text>
    </Button>
  </Row>
)};
export const BackToSpecificScreen = (props) => {
  const {route}=props
  const navigate = useNavigate();

  return (
  <Row
    style={{
      alignContent: "center",
      width: "8%",
    }}
  >
    <Button
      icon={<LeftOutlined />}
      type="link"
      style={{
        marginTop: "auto",
        marginBottom: "auto",
        color:'var(--primaryColor)'
      }}
      onClick={()=>navigate(route)}
    >
      <Text style={{ marginTop: "auto", marginBottom: "auto", fontSize: 15,color:'var(--primaryColor)' }}>
        Back
      </Text>
    </Button>
  </Row>
)};
export const AmountFormat = (amount, currency, precision) => {
  return (
    <Statistic
    valueStyle={{fontSize: "1.2rem", color: "var(--of-black)"}}
      prefix={currency}
      value={amount ? amount : 0}
      precision={precision ? precision : 2}
    />
  );
};