import React, { useState, useEffect, useRef } from "react";
import { connect, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

//ANT DESIGN IMPORTS
import {
  Row,
  Col,
  Card,
  Divider,
  Button,
  Form,
  Spin,
  Typography,
  Select,
  Descriptions,
  message,
  Layout,
  Space,
  Avatar,
} from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";

//PROJECT IMPORTS
import Main from "../../../components/layout/Main";
import { BOM } from "../../../services/definedMethod";
import cnt from "../../../services/definedConstant";
import { apiConfig, labelConfig } from "../configuration.config";
import appConfig from "../../../config/appConfig";
import {
  AmountFormat,
  BackToPreviousScreen,
} from "../../../components/Facility";

const { Text } = Typography;
const CheckRate = (props) => {
  const navigate = useNavigate();
  const [useForm] = Form.useForm();
  const { userData } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [amountOptions, setAmountOptions] = useState(0);
  const [template, setTemplate] = useState();
  const [feeTransfer, setFeeTransfer] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [showReview, setShowReview] = useState(false);

  const [countryData, setCountryData] = useState([]);
  const [countryFlag, setCountryFlag] = useState(
    "https://flagsapi.com/CD/flat/64.png"
  );

  const items = [
    // {
    //   key: "2",
    //   label: "Recipient Country",
    //   children: "DRC",
    // },
    {
      key: "3",
      label: "Rate",
      children: `1.00 USD = ${template?.exchangeRate}`,
    },
    {
      key: "4",
      label: `GdeG's Fee (${template?.feeRate}%)`,
      children: `${template?.companyCurrency?.symbol} ${BOM.HandleMathRound(
        feeTransfer
      )}`,
    },
    {
      key: "5",
      label: (
        <Text
          style={{
            color: "var(--black)",
            fontSize: "1rem",
          }}
        >
          Total to Pay
        </Text>
      ),
      children: (
        <Text
          style={{
            color: "var(--primaryColor)",
            fontSize: "1.2rem",
          }}
        >
          {`
            ${template?.companyCurrency?.symbol} ${BOM.HandleMathRound(
            totalAmount
          )}`}
          {/* {AmountFormat(
            BOM.HandleMathRound(totalAmount),
            template?.companyCurrency?.symbol
          )} */}
        </Text>
      ),
    },
  ];

  useEffect(() => {
    getExchangeRateTemplate();
  }, []);

  const getExchangeRateTemplate = () => {
    let body = {
      apiKey: "gdeg",
    };
    setIsLoading(true);
    BOM.FetchReqAction(body, apiConfig.GetExchangeRateTemplate, (err, res) => {
      if (err) {
        const errorMsg = err?.errorData ? err?.errorData[0] : err.message;
        BOM.AlertMsg(cnt.DANGER, errorMsg);
        setIsLoading(false);
      } else {
        const objDetail = res.exchangeRateTemplate;
        let tempCountry = [];

        objDetail?.countryOptions?.forEach((element) => {
          tempCountry.push({
            value: element.ID,
            label: `${element.COUNTRY_NAME} ( ${element.SORTNAME} )`,
            flag: element.COUNTRY_FLAG,
          });
        });
        setCountryData(tempCountry);

        setTemplate({
          limitAmount: objDetail?.companyInfo?.GLOBAL_LIMIT,
          feeRate: objDetail?.companyInfo?.GLOBAL_FEE_RATE,
          exchangeRate: objDetail?.companyInfo?.EXCHANGE_RATE,
          companyCurrency: {
            name: objDetail?.companyCurrency?.CUR_NAME,
            symbol: objDetail?.companyCurrency?.CUR_SYMBOL,
          },
        });
        generateAmount(objDetail?.companyInfo?.GLOBAL_LIMIT);
        // STOP LOAD
        setIsLoading(false);
      }
    });
  };
  const onSelectCountry = (value, objParam) => {
    setCountryFlag(objParam.flag);
  };
  const generateAmount = (length) => {
    const tempAmount = [];
    for (let index = 10; index <= length; index++) {
      tempAmount.push({
        value: index,
        label: index,
      });
    }
    setAmountOptions(tempAmount);
  };
  const onSelectAmount = (param) => {
    const fee = (param * parseFloat(template?.feeRate)) / 100;

    //display to the UI
    setFeeTransfer(BOM.HandleMathRound(fee));
    setTotalAmount(BOM.HandleMathRound(fee + param));
    setShowReview(param > 0 ? true : false);
  };
  const onSendMoney = () => {
    BOM.AlertMsg(cnt.INFO, `To send money, please login or register an account with the GdeG web application.`);
    navigate("/")
  };

  return (
    <Layout className="layout-default layout-signin">
    <Spin spinning={isLoading} size="large">
      <Row gutter={[24, 0]}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mb-24">
          <BackToPreviousScreen />
          <Card className="header-solid h-full">
          <Form
              layout="vertical"
              autoComplete="off"
            >
              <Col span={24} style={{ padding: "0px 8px" }}>
                <Form.Item label={labelConfig.sendTo} name="countryId">
                  <Space.Compact>
                    {/* <Avatar src={countryFlag} /> */}
                    <Avatar src={<img src={countryFlag} alt="flag" />} />
                    <Select
                      filterOption={(input, option) =>
                        (option?.label?.toLowerCase() ?? "").includes(
                          input?.toLowerCase()
                        )
                      }
                      defaultValue="Democratic Republic of Congo"
                      placeholder={BOM.handlePlaceholderMsg(
                        `${labelConfig.country}*`,
                        "select"
                      )}
                      options={countryData}
                      showSearch
                      onChange={onSelectCountry}
                    />
                  </Space.Compact>
                </Form.Item>
              </Col>

              <Col md={12} xs={24}>
                <Form.Item
                  // label={labelConfig.sendAmount}
                  label={`${labelConfig.sendAmount} ( Daily Limit:  ${template?.companyCurrency?.symbol} ${template?.limitAmount} )`}
                  name="amount"
                  rules={[
                    {
                      required: true,
                      message: BOM.CheckReqFld(labelConfig.amount),
                    },
                  ]}
                >
                  <Select
                    placeholder={BOM.handlePlaceholderMsg(
                      `${labelConfig.amount} *`,
                      "select"
                    )}
                    options={amountOptions}
                    showSearch
                    onChange={onSelectAmount}
                  />
                </Form.Item>
              </Col>
              {showReview && (
                <>
                  <Col md={24} xs={24} className="mb-24">
                    <Descriptions bordered items={items} />
                  </Col>
                  <Col xs={24} md={24} className="mb-24">
                    <p>
                      <InfoCircleOutlined className="gg-menu-icon" /> The amount
                      to be received is only an estimate. When you finalize your
                      order, the total amount payable to your receiver will be
                      shown.
                    </p>
                  </Col>

                  <Col xs={24} md={24} className="mb-24">
                    <Button
                     onClick={()=>onSendMoney()}
                      className="of-primaryBtnColor"
                      loading={isLoading}
                    >
                      {labelConfig.sendMoney}
                    </Button>
                  </Col>
                </>
              )}
            </Form>
          </Card>
        </Col>
      </Row>
    </Spin>
    </Layout>
  );
};

export default CheckRate;
