import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

//ANT DESIGN IMPORTS
import {
  Row,
  Col,
  Card,
  Button,
  Typography,
  Radio,
} from "antd";
import {
  PlusOutlined,
} from "@ant-design/icons";

//PROJECT IMPORTS
import Main from "../../components/layout/Main";
import { labelConfig } from "./transfer.config";
import { BackToSpecificScreen } from "../../components/Facility";
import DisplayCurrentTransfer from "./subScreens/DisplayCurrentTransfer";
import DisplayCompletedTransfer from "./subScreens/DisplayCompletedTransfer";

const { Text } = Typography;

const ManageTransfer = (props) => {
  const navigate = useNavigate();
  const { userData } = props;
  const { PROFILE_ID, isAdmin, session } = userData;
  const [selectedComponent, setSelectedComponent] = useState(
    <DisplayCurrentTransfer profileId={PROFILE_ID} token={session} />
  );

  const onChangeTransferStatus = (e) => {
    switch (e.target.value?.toLowerCase()) {
      case "current":
        setSelectedComponent(
          <DisplayCurrentTransfer profileId={PROFILE_ID} token={session} />
        );
        break;
      case "completed":
        setSelectedComponent(
          <DisplayCompletedTransfer profileId={PROFILE_ID} token={session} />
        );
        break;
      default:
        setSelectedComponent("Please select a status");
        break;
    }
  };

  return (
    <Main>
      <Row gutter={[24, 0]}>
        <Col span={24} md={24} className="mb-24">
          <BackToSpecificScreen
            route={isAdmin ? "/admin-dashboard" : "/dashboard"}
          />
          <Card
            className="header-solid h-full"
            bordered={false}
            extra={
              <>
                <Button
                  onClick={(e) => navigate("/new-transfer")}
                  className="of-primaryBtnColor"
                  style={{ marginTop: "20px", marginBottom: "20px" }}
                >
                  <PlusOutlined /> {labelConfig.newTransferBtn}
                </Button>
                <Radio.Group
                  onChange={onChangeTransferStatus}
                  defaultValue={labelConfig.current}
                  className="radio-title-button"
                  size="small"
                  style={{ marginTop: "10px", marginBottom: "20px" }}
                >
                  <Radio.Button value={labelConfig.current}>
                    {labelConfig.current.toUpperCase()}
                  </Radio.Button>
                  <Radio.Button value={labelConfig.completed}>
                    {labelConfig.completed.toUpperCase()}
                  </Radio.Button>
                </Radio.Group>
              </>
            }
          >
            {selectedComponent}
          </Card>
        </Col>
      </Row>
    </Main>
  );
};

const mapStateToProps = (state) => ({
  userData: state.AuthenticationData.userData,
});

export default connect(mapStateToProps, null)(ManageTransfer);


