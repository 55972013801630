import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import Notiflix from "notiflix";

//ANT DESIGN IMPORTS
import {
  Row,
  Col,
  Card,
  Tooltip,
  Button,
  List,
  Skeleton,
  Avatar,
  message,
} from "antd";
import {
  DoubleRightOutlined,
  UserOutlined,
  EditOutlined,
  PlusOutlined,
  DeleteOutlined,
  SendOutlined,
} from "@ant-design/icons";

//PROJECT IMPORTS
import Main from "../../components/layout/Main";
import { BOM } from "../../services/definedMethod";
import cnt from "../../services/definedConstant";
import { apiConfig, labelConfig, staticConfig } from "./recipient.config";
import { BackToSpecificScreen } from "../../components/Facility";

const ManageRecipient = (props) => {
  const navigate = useNavigate();
  const { userData } = props;
  const { PROFILE_ID, ROLE_SHORT, isAdmin, session, currentTransfer } =
    userData;
  const [recipientList, setRecipientList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [hasCurrentTransfet, setHasCurrentTransfet] = useState(true);

  useEffect(() => {
    getRecipientList();
  }, []);

  const getRecipientList = () => {
    let body = {
      profileId: PROFILE_ID,
      roleShort: ROLE_SHORT,
      rcpSponsor: PROFILE_ID,
      // status,
      token: session,
    };
    setIsLoading(true);
    BOM.FetchReqAction(body, apiConfig.GetRecipient, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err?.errorData[0]);
        setIsLoading(false);
      } else {
        const objList = res.recipientListing;
        const tempRecipient = [];

        objList.forEach((element) => {
          tempRecipient.push({
            key: element.RCP_ID,
            rcpId: element.RCP_ID,
            title: `${element.RCP_FIRSTNAME} ${element.RCP_LASTNAME}`,
            titleCustom: (
              <>
                {`${element.RCP_FIRSTNAME} ${element.RCP_LASTNAME} `}
                <img style={{ width: 20 }} src={element.COUNTRY_FLAG} />{" "}
                {element.COUNTRY_NAME}
              </>
            ),
            avatar: DoubleRightOutlined,
            description: `${element.PAYMENT_TYPE_NAME} Payment`,
            flag:element.COUNTRY_FLAG,
            toCountrySortname:element?.COUNTRY_SORTNAME
            // description: `${element.MOMO_NAME} (${element.MOMO_OPERATOR})`,
          });
        });
        setRecipientList(tempRecipient);

        /**
         * CURRENT TRANSFER LOGIC
         */
        const hasCurrentTfr = BOM.IsEmpty(res?.currentTransfer) ? false : true
        setHasCurrentTransfet(hasCurrentTfr);

        if (hasCurrentTfr) {
          BOM.AlertMsg(cnt.WARNING, "GdeG only accepts one transfer order at a time. Please pay for or cancel your current transfer. ");
        }
        //STOP LOAD
        setIsLoading(false);
      }
    });
  };
  const onDelete = (objParam) => {
    // console.log(objParam);return

    let body = {
      profileId: PROFILE_ID,
      recipientId: objParam.rcpId,
      token: session,
    };

    // console.log(body);
    // return;
    let confirmMessage = `<p>Are you sure you want to delete ${objParam.title}?</p>&nbsp;`;
    Notiflix.Confirm.show(
      "Delete recipient",
      `${confirmMessage}`,
      "Yes",
      "No",
      function () {
        // Yes
        setIsLoading(true);
        BOM.FetchReqAction(body, apiConfig.DeleteRecipient, (err, res) => {
          if (err) {
            const errorMsg = err?.errorData ? err?.errorData[0] : err.message;
            BOM.AlertMsg(cnt.DANGER, errorMsg);
            setIsLoading(false);
          } else {
            message.success(res.message, 10);
            // BOM.AlertMsg(cnt.SUCCESS, res.message);
            getRecipientList();
            setIsLoading(false);
          }
        });
      },
      function () {
        // No button
      }
    );
  };
  return (
    <Main>
      <Row gutter={[24, 0]}>
        <Col span={24} md={24} className="mb-24">
          <BackToSpecificScreen
            route={isAdmin ? "/admin-dashboard" : "/dashboard"}
          />
          <Card
            className="header-solid h-full"
            bordered={false}
            title={`Recipients (${recipientList.length})`}
            extra={
              <>
                <Button
                  onClick={() => navigate("/new-recipient")}
                  className="gg-primaryBtnColor-auto"
                >
                  <span>
                    <PlusOutlined /> Add Recipient
                  </span>
                </Button>
                ,
              </>
            }
          >
            <Row>
              {!isLoading && recipientList.length === 0 && `No recipient`}
            </Row>
            <Skeleton loading={isLoading} active avatar />
            <List
              itemLayout="vertical"
              dataSource={recipientList}
              split
              className="conversations-list"
              renderItem={(item) => (
                <List.Item
                 
                  extra={
                    <>
                    <Button
                      disabled={hasCurrentTransfet}
                      icon={<SendOutlined />}
                      size="small"
                      className="gg-send-btn"
                      onClick={() =>
                        navigate("/new-transfer", {
                          state: {
                            id: item.rcpId,
                            toCountrySortname: item.toCountrySortname,
                            recipientNames:item?.title,
                            flag:item.flag
                          },
                        })
                      }
                    >
                      Send now
                    </Button> &nbsp;
                    <Button
                      disabled={hasCurrentTransfet}
                      icon={<EditOutlined />}
                      size="small"
                      shape="round"
                      onClick={() =>
                        navigate("/update-recipient", {
                          state: { id: item.rcpId },
                        })
                      }
                    /> &nbsp;
                    <Button
                      disabled={hasCurrentTransfet}
                      icon={<DeleteOutlined />}
                      size="small"
                      shape="round"
                      onClick={() => onDelete(item)}
                    />
                  
                  </>}
                >
                  <List.Item.Meta
                    avatar={
                      <UserOutlined className="gg-menu-icon" />
                      // <Avatar shape="square" size={48} src={item.avatar} />
                    }
                    title={item.titleCustom}
                    description={item.description}
                  />
                </List.Item>
              )}
            />
          </Card>
        </Col>
      </Row>
    </Main>
  );
};

const mapStateToProps = (state) => ({
  userData: state.AuthenticationData.userData,
});

export default connect(mapStateToProps, null)(ManageRecipient);
