import React, { useState, useEffect, useRef } from "react";
import { connect, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Notiflix from "notiflix";

//ANT DESIGN IMPORTS
import {
  Row,
  Col,
  Card,
  Divider,
  Button,
  Form,
  Input,
  Spin,
  Select,
  Typography,
  message,
} from "antd";

//PROJECT IMPORTS
import Main from "../../components/layout/Main";
import { BOM } from "../../services/definedMethod";
import cnt from "../../services/definedConstant";
import { apiConfig, labelConfig } from "./recipient.config";
import appConfig from "../../config/appConfig";
import { BackToPreviousScreen } from "../../components/Facility";

const { Text } = Typography;
const UpdateRecipient = (props) => {
  //payment gatway info
  const { paymentGatway } = appConfig;
  const { sandbox, live } = paymentGatway;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [useForm] = Form.useForm();
  const { state } = useLocation();
  const { userData } = props;
  const { PROFILE_ID, COUNTRY_ID, session } = userData;
  const [isLoading, setIsLoading] = useState(false);
  const [countryData, setCountryData] = useState([]);
  const [payoutMethodData, setPayoutMethodData] = useState([]);
  const [momoData, setMomoData] = useState([]);
  const [countryPhoneCode, setCountryPhoneCode] = useState("+243");
  const [isCountrySelected, setIsCountrySelected] = useState(false);
  const [recipientDetail, setRecipientDetail] = useState({});
  const [payoutMethodDisplay, setPayoutMethodDisplay] = useState("");
  const [criteria, setCriteria] = useState("");

  useEffect(() => {
    getRcpDetail();
    getRcpTemplate();
  }, []);
  const getRcpDetail = () => {
    let body = {
      profileId: PROFILE_ID,
      recipientId: state.id,
      token: session,
    };
    setIsLoading(true);
    BOM.FetchReqAction(body, apiConfig.GetRecipientDetail, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err?.errorData[0]);
        setIsLoading(false);
      } else {
        // return
        const objDetail = res.recipientDetail;
        setRecipientDetail(objDetail);
        // SHOW THE SAVED DATA
        useForm.setFieldsValue({
          rcpFirstname: objDetail?.RCP_FIRSTNAME,
          rcpLastname: objDetail?.RCP_LASTNAME,
          rcpPhoneNo: objDetail?.RCP_PHONE_NO,
          paymentTypeId: objDetail?.PAYMENT_TYPE_ID,
          momoId: objDetail?.MOMO_ID,
          countryId: objDetail?.COUNTRY_ID,
          countryFlag: objDetail?.COUNTRY_FLAG,
          rcpBankName: objDetail?.RCP_BANK_NAME,
          rcpHolder: objDetail?.RCP_BANK_HOLDER,
          rcpAccountType: objDetail?.RCP_ACCOUNT_TYPE,
          rcpAccountNumber: objDetail?.RCP_ACCOUNT_NUMBER,
          rcpBranch: objDetail?.RCP_BRANCH,
          rcpCryptoWalletName: objDetail?.RCP_CRYPTO_WALLET_NAME,
          rcpCryptoWalletAddress: objDetail?.RCP_CRYPTO_WALLET_ADDRESS,
        });

        setIsCountrySelected(true);
        setCountryPhoneCode(objDetail.COUNTRY_PHONE_CODE);
        handlePayoutMethod(null, { label: objDetail?.PAYMENT_TYPE_NAME });
        //STOP LOAD
        setIsLoading(false);
      }
    });
  };
  const getRcpTemplate = () => {
    let body = {
      profileId: PROFILE_ID,
      token: session,
    };
    setIsLoading(true);
    BOM.FetchReqAction(body, apiConfig.GetRecipientTemplate, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err.message);
        setIsLoading(false);
      } else {
        // return
        const objList = res.recipientTemplate;
        let tempCountry = [];
        let tempPayoutMethod = [];
        let tempPayoutOrMomo = [];

        objList?.countryOptions
          ?.filter((item) => item.ID !== COUNTRY_ID)
          ?.forEach((element) => {
            tempCountry.push({
              value: element.ID,
              searchLabel: element.COUNTRY_NAME,
              label: (
                <>
                  <img style={{ width: 20 }} src={element.COUNTRY_FLAG} />{" "}
                  {element.COUNTRY_NAME}
                </>
              ),
              phoneCode: element.COUNTRY_PHONE_CODE,
            });
          });

        setCountryData(tempCountry);

        // PAYOUT METHOD
        objList?.payoutMethodOptions?.forEach((element) => {
          tempPayoutMethod.push({
            value: element.PAYMENT_TYPE_ID,
            label: element.PAYMENT_TYPE_NAME,
          });
        });

        setPayoutMethodData(tempPayoutMethod);

        objList?.payoutMomoOptions?.forEach((element) => {
          tempPayoutOrMomo.push({
            value: element.MOMO_ID,
            label: `${element.MOMO_NAME} ( ${element.MOMO_OPERATOR} )`,
          });
        });

        setMomoData(tempPayoutOrMomo);
        //STOP LOAD
        setIsLoading(false);
      }
    });
  };
  const onSelectCountry = (value, objParam) => {
    setCountryPhoneCode(objParam.phoneCode);
  };
  const handlePayoutMethod = (_, keyParam) => {
    // console.log(keyParam);return

    setCriteria(keyParam?.label);
    switch (keyParam?.label?.toLowerCase()) {
      case "bank":
        return setPayoutMethodDisplay(<BankFields />);
      case "crypto":
        return setPayoutMethodDisplay(<CryptoFields />);
      case "mobile":
        return setPayoutMethodDisplay(<MobileFields momoData={momoData} />);
      default:
        return <></>;
    }
  };
  const onSubmit = (formValues) => {
    // formValues.rcpSponsor = PROFILE_ID;
    formValues.recipientId = state.id;
    formValues.criteria = criteria;

    let body = {
      profileId: PROFILE_ID,
      ...formValues,
      token: session,
    };

    // console.log(body);
    // return;
    let confirmMessage =
      "<p>Please double-check that the information you entered is correct.We cannot refund money if it is sent to the wrong recipient!</p>&nbsp;";
    confirmMessage += "<p>By confirming, you agree these terms.</p>";
    Notiflix.Confirm.show(
      "Confirm recipient creation",
      `${confirmMessage}`,
      "Confirm",
      "Cancel",
      function () {
        // Yes
        setIsLoading(true);
        BOM.FetchReqAction(body, apiConfig.UpdateRecipient, (err, res) => {
          if (err) {
            const errorMsg = err?.errorData ? err?.errorData[0] : err.message;
            BOM.AlertMsg(cnt.DANGER, errorMsg);
            setIsLoading(false);
          } else {
            message.success(res.message,10);
            navigate("/manage-recipient");
            setIsLoading(false);
          }
        });
      },
      function () {
        // No button
      }
    );
  };
  return (
    <Main>
      <Spin spinning={isLoading} size="large">
        <Row gutter={[24, 0]}>
          <Col span={24} md={24} className="mb-24">
            <BackToPreviousScreen />
            <Card className="header-solid h-full">
              <Form
                form={useForm}
                layout="vertical"
                autoComplete="off"
                onFinish={onSubmit}
              >
                <Row>
                  <Col span={24} style={{ padding: "0px 8px" }}></Col>
                  <Divider orientation="left">
                    {labelConfig.recipientPersonalInformation}
                  </Divider>
                  <Col span={24} style={{ padding: "0px 8px" }}>
                    <Form.Item
                      label={labelConfig.firstName}
                      name="rcpFirstname"
                      rules={[
                        {
                          required: true,
                          message: BOM.CheckReqFld(labelConfig.firstName),
                        },
                      ]}
                    >
                      <Input
                        maxLength={40}
                        placeholder={BOM.handlePlaceholderMsg(
                          `${labelConfig.firstName} *`
                        )}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24} style={{ padding: "0px 8px" }}>
                    <Form.Item
                      label={labelConfig.lastName}
                      name="rcpLastname"
                      rules={[
                        {
                          required: true,
                          message: BOM.CheckReqFld(labelConfig.lastName),
                        },
                      ]}
                    >
                      <Input
                        maxLength={40}
                        placeholder={BOM.handlePlaceholderMsg(
                          `${labelConfig.lastName} *`
                        )}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={24} style={{ padding: "0px 8px" }}>
                    <Form.Item
                      label={<>{labelConfig.country} </>}
                      name="countryId"
                      rules={[
                        {
                          required: true,
                          message: BOM.CheckReqFld(labelConfig.country),
                        },
                      ]}
                    >
                      <Select
                        disabled
                        className="gg-bold-disable-input"
                        filterOption={(input, option) =>
                          (option?.searchLabel?.toLowerCase() ?? "").includes(
                            input?.toLowerCase()
                          )
                        }
                        size="large"
                        placeholder={BOM.handlePlaceholderMsg(
                          `${labelConfig.country} *`,
                          "select"
                        )}
                        options={countryData}
                        showSearch
                        onChange={onSelectCountry}
                      />
                    </Form.Item>
                  </Col>
                  {isCountrySelected && (
                    <>
                      <Col span={24} style={{ padding: "0px 8px" }}>
                        <Form.Item
                          label={
                            <>
                              {labelConfig.phoneNo} &nbsp;{" "}
                              <Text
                                style={{
                                  fontWeight: "bold",
                                  color: "var(--primaryColor)",
                                }}
                              >{`( ${countryPhoneCode} )`}</Text>
                            </>
                          }
                          name="rcpPhoneNo"
                          rules={[
                            {
                              required: true,
                              message: BOM.CheckReqFld(labelConfig.phoneNo),
                            },
                            {
                              min: 9,
                              message: BOM.CheckReqFld(
                                "Minimum of 9 digits is required"
                              ),
                            },
                            {
                              max: 9,
                              message: BOM.CheckReqFld(
                                "Maximum of 9 digits is required"
                              ),
                            },
                          ]}
                        >
                          {/* <Space.Compact>
                          <Input
                            style={{
                              width: "20%",
                            }}
                            value={countryPhoneCode}
                            disabled
                            className="gg-bold-disable-input"
                          />
                          <Input
                            className="inputFieldNumber"
                            maxLength={20}
                            placeholder={BOM.handlePlaceholderMsg(
                              `${labelConfig.phoneNo} *`
                            )}
                          />
                        </Space.Compact> */}
                          <Input
                            className="inputFieldNumber"
                            maxLength={20}
                            placeholder={BOM.handlePlaceholderMsg(
                              `${labelConfig.phoneNo} *`
                            )}
                          />
                        </Form.Item>
                      </Col>
                      <Divider orientation="left">
                        {labelConfig.recipientPayoutMethod}
                      </Divider>

                      <Col span={24} style={{ padding: "0px 8px" }}>
                        <Form.Item
                          label={`${labelConfig.payoutMethod}`}
                          name="paymentTypeId"
                          rules={[
                            {
                              required: true,
                              message: BOM.CheckReqFld(
                                labelConfig.payoutMethod
                              ),
                            },
                          ]}
                        >
                          <Select
                            filterOption={(input, option) =>
                              (option?.label?.toLowerCase() ?? "").includes(
                                input?.toLowerCase()
                              )
                            }
                            size="large"
                            placeholder={BOM.handlePlaceholderMsg(
                              `${labelConfig.payoutMethod} *`,
                              "select"
                            )}
                            onChange={handlePayoutMethod}
                            options={payoutMethodData}
                            showSearch
                          />
                        </Form.Item>
                      </Col>
                      {payoutMethodDisplay}
                    </>
                  )}
                </Row>
                <Form.Item>
                  <Button
                    htmlType="submit"
                    className="of-primaryBtnColor"
                    loading={isLoading}
                  >
                    {labelConfig.submit}
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </Col>
        </Row>
      </Spin>
    </Main>
  );
};

export default UpdateRecipient;

const BankFields = (props) => {
  return (
    <>
      <Col span={24} style={{ padding: "0px 8px" }}>
        <Form.Item
          label={labelConfig.bankName}
          name="rcpBankName"
          rules={[
            {
              required: true,
              message: BOM.CheckReqFld(labelConfig.bankName),
            },
          ]}
        >
          <Select
            filterOption={(input, option) =>
              (option?.label?.toLowerCase() ?? "").includes(
                input?.toLowerCase()
              )
            }
            size="large"
            placeholder={BOM.handlePlaceholderMsg(
              `${labelConfig.bankName} *`,
              "select"
            )}
            options={[
              { label: "FNB/RMB", value: "FNB/RMB" },
              // { label: "Capitec", value: "Capitec" },
            ]}
            showSearch
          />
        </Form.Item>
      </Col>
      <Col span={24} style={{ padding: "0px 8px" }}>
        <Form.Item
          label={labelConfig.holder}
          name="rcpHolder"
          rules={[
            {
              required: true,
              message: BOM.CheckReqFld(labelConfig.holder),
            },
          ]}
        >
          <Input
            maxLength={40}
            placeholder={BOM.handlePlaceholderMsg(`${labelConfig.holder} *`)}
          />
        </Form.Item>
      </Col>
      <Col span={24} style={{ padding: "0px 8px" }}>
        <Form.Item
          label={labelConfig.accountType}
          name="rcpAccountType"
          rules={[
            {
              required: true,
              message: BOM.CheckReqFld(labelConfig.accountType),
            },
          ]}
        >
          <Select
            filterOption={(input, option) =>
              (option?.label?.toLowerCase() ?? "").includes(
                input?.toLowerCase()
              )
            }
            size="large"
            placeholder={BOM.handlePlaceholderMsg(
              `${labelConfig.accountType} *`,
              "select"
            )}
            options={[
              { label: "Current", value: "Current" },
              { label: "Saving", value: "Saving" },
            ]}
            showSearch
          />
        </Form.Item>
      </Col>
      <Col span={24} style={{ padding: "0px 8px" }}>
        <Form.Item
          label={labelConfig.accountNumber}
          name="rcpAccountNumber"
          rules={[
            {
              required: true,
              message: BOM.CheckReqFld(labelConfig.accountNumber),
            },
            {
              min: 5,
              message: BOM.CheckReqFld("Minimum of 5 digits is required"),
            },
            {
              max: 15,
              message: BOM.CheckReqFld("Maximum of 15 digits is required"),
            },
          ]}
        >
          <Input
            maxLength={40}
            placeholder={BOM.handlePlaceholderMsg(
              `${labelConfig.accountNumber} *`
            )}
          />
        </Form.Item>
      </Col>
      <Col span={24} style={{ padding: "0px 8px" }}>
        <Form.Item
          label={labelConfig.branch}
          name="rcpBranch"
          rules={[
            {
              required: true,
              message: BOM.CheckReqFld(labelConfig.branch),
            },
            {
              min: 3,
              message: BOM.CheckReqFld("Minimum of 3 digits is required"),
            },
            {
              max: 10,
              message: BOM.CheckReqFld("Maximum of 10 digits is required"),
            },
          ]}
        >
          <Input
            maxLength={15}
            placeholder={BOM.handlePlaceholderMsg(`${labelConfig.branch} *`)}
          />
        </Form.Item>
      </Col>
    </>
  );
};

const CryptoFields = (props) => {
  return (
    <>
      <Col span={24} style={{ padding: "0px 8px" }}>
        <Form.Item
          label={labelConfig.walletName}
          name="rcpCryptoWalletName"
          rules={[
            {
              required: true,
              message: BOM.CheckReqFld(labelConfig.walletName),
            },
          ]}
        >
          <Select
            filterOption={(input, option) =>
              (option?.label?.toLowerCase() ?? "").includes(
                input?.toLowerCase()
              )
            }
            size="large"
            placeholder={BOM.handlePlaceholderMsg(
              `${labelConfig.walletName} *`,
              "select"
            )}
            options={[
              { label: "Bitcoin (BTC)", value: "Bitcoin (BTC)" },
              { label: "Tether (USDT)", value: "Tether (USDT)" },
            ]}
            showSearch
          />
        </Form.Item>
      </Col>
      <Col span={24} style={{ padding: "0px 8px" }}>
        <Form.Item
          label={labelConfig.walletAddress}
          name="rcpCryptoWalletAddress"
          rules={[
            {
              required: true,
              message: BOM.CheckReqFld(labelConfig.walletAddress),
            },
          ]}
        >
          <Input
            maxLength={40}
            placeholder={BOM.handlePlaceholderMsg(
              `${labelConfig.walletAddress} *`
            )}
          />
        </Form.Item>
      </Col>
    </>
  );
};
const MobileFields = (props) => {
  const { momoData } = props;
  return (
    <>
      <Col span={24} style={{ padding: "0px 8px" }}>
        <Form.Item
          label={`${labelConfig.payout}`}
          name="momoId"
          rules={[
            {
              required: true,
              message: BOM.CheckReqFld(labelConfig.payout),
            },
          ]}
        >
          <Select
            filterOption={(input, option) =>
              (option?.label?.toLowerCase() ?? "").includes(
                input?.toLowerCase()
              )
            }
            size="large"
            placeholder={BOM.handlePlaceholderMsg(
              `${labelConfig.payout} *`,
              "select"
            )}
            options={momoData}
            showSearch
          />
        </Form.Item>
      </Col>
    </>
  );
};
