import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

//ANT DESIGN IMPORTS
import {
  Row,
  Col,
  Card,
  Divider,
  Button,
  Form,
  Input,
  InputNumber,
} from "antd";

//PROJECT IMPORTS
import Main from "../../components/layout/Main";
import { BOM } from "../../services/definedMethod";
import cnt from "../../services/definedConstant";
import { apiConfig, labelConfig } from "./auth.config";
import { BackToPreviousScreen } from "../../components/Facility";

const ChangePassword = (props) => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { userData } = props;
  const { PROFILE_ID, session } = userData;
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = (formValues) => {
    setIsLoading(true);
    let body = {
      profileId: PROFILE_ID,
      ...formValues,
      token: session,
    };
    // console.log(body);
    // return;
    BOM.FetchReqAction(body, apiConfig.ChangePassword, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err.message);
        setIsLoading(false);
      } else {
        navigate("/sign-in")
        setIsLoading(false);
      }
    });
  };

  return (
    <Main>
      <Row gutter={[24, 0]}>
        <Col span={24} md={24} className="mb-24">
        <BackToPreviousScreen/>
          <Card className="header-solid h-full">
            <Divider orientation="left">{labelConfig.changePwd}</Divider>

            <Form layout={"vertical"} onFinish={onSubmit}>
              <Row>
                <Col span={24} style={{ padding: "0px 8px" }}>
                  <Form.Item
                    label={labelConfig.currentPassword}
                    name="currentPassword"
                    rules={[
                      {
                        required: true,
                        message: BOM.handleReqFldMsg(labelConfig.newPassword),
                      },
                      {
                        min: 8,
                        message: "Minimum password length is 8 characters",
                      },
                      {
                        max: 12,
                        message: "Maximum password length is 12 characters",
                      },
                      {
                        pattern: BOM.RegExValidation("password"),
                        message:
                          "At least one uppercase, lowercase, digit, and special character.",
                      },
                    ]}
                  >
                    <Input.Password
                      placeholder={BOM.handlePlaceholderMsg(
                        labelConfig.newPassword
                      )}
                    />
                  </Form.Item>
                </Col>
                <Col span={24} style={{ padding: "0px 8px" }}>
                  <Form.Item
                    label={labelConfig.newPassword}
                    name="newPassword"
                    rules={[
                      {
                        required: true,
                        message: BOM.handleReqFldMsg(labelConfig.newPassword),
                      },
                      {
                        min: 8,
                        message: "Minimum password length is 8 characters",
                      },
                      {
                        max: 12,
                        message: "Maximum password length is 12 characters",
                      },
                      {
                        pattern: BOM.RegExValidation("password"),
                        message:
                          "At least one uppercase, lowercase, digit, and special character.",
                      },
                    ]}
                    hasFeedback
                  >
                   <Input.Password
                      placeholder={BOM.handlePlaceholderMsg(
                        labelConfig.newPassword
                      )}
                    />
                  </Form.Item>
                </Col>
                <Col span={24} style={{ padding: "0px 8px" }}>
                  <Form.Item
                    label={labelConfig.confirmPassword}
                    name="confirmPassword"
                    rules={[
                      {
                        required: true,
                        message: BOM.handleReqFldMsg(
                          labelConfig.confirmPassword
                        ),
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (
                            !value ||
                            getFieldValue("newPassword") === value
                          ) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error(
                              "The new password you entered does not match."
                            )
                          );
                        },
                      }),
                    ]}
                    hasFeedback
                  >
                    <Input.Password
                      placeholder={BOM.handlePlaceholderMsg(
                        labelConfig.confirmPassword
                      )}
                    />
                  </Form.Item>
                </Col>
                {/* <Col span={24}></Col> */}
              </Row>
              <Form.Item>
                <Button
                  htmlType="submit"
                  className="of-primaryBtnColor"
                  loading={isLoading}
                >
                  {labelConfig.submitBtn}
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </Main>
  );
};

const mapStateToProps = (state) => ({
  userData: state.AuthenticationData.userData,
});

export default connect(mapStateToProps, null)(ChangePassword);
