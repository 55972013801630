import { BOM } from "../../services/definedMethod";
import { Tag, message } from "antd";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  SyncOutlined,
  ClockCircleOutlined,
} from "@ant-design/icons";

/*!*******************************************************************************!
  !*** API CONFIG                                                              ***!
  \*******************************************************************************/
export const apiConfig = {
  NewRecipient: BOM.baseUrl("newRecipient"),
  GetRecipient: BOM.baseUrl("getRecipient"),
  UpdateRecipient: BOM.baseUrl("updateRecipient"),
  GetRecipientTemplate: BOM.baseUrl("getRecipientTemplate"),
  GetRecipientDetail: BOM.baseUrl("getRecipientDetail"),
  DeleteRecipient: BOM.baseUrl("deleteRecipient"),
  // SearchTransfer: BOM.baseUrl("searchTransfer"),
  // UpdateTransferStatus: BOM.baseUrl("updateTransferStatus"),
  // GetMomo: BOM.baseUrl("getMomo"),
  // GetTransferTemplate: BOM.baseUrl("getTransferTemplate"),
  // GetTransferCount: BOM.baseUrl("getTransferCount"),
};

/*!*******************************************************************************!
  !*** AUTH LABEL CONFIG                                                       ***!
  \*******************************************************************************/
export const labelConfig = {
  recipientPersonalInformation: "Recipient Personal Details",
  recipientPayoutMethod: "Recipient Payout Method",
  payoutMethod: "Payout Method",
  payout: "Payout",
  firstName: "First Name",
  lastName: "Last Name",
  middleName: "Middle Name",
  phoneNo: "Phone Number",
  country: "Country",
  submit: "Submit",
  bankName: "Bank Name",
  holder: "Holder",
  accountType: "Account Type",
  accountNumber: "Account Number",
  branch: "Branch",
  cryptoWallet: "Crypto Wallet",
  walletName: "Wallet Name",
  walletAddress: "Wallet Address",
  branch: "Branch",
  branch: "Branch",

  // transferInformation: "Transfer Information",
  // generalInformation: "General Information",
  // senderInformation: "Sender Information",
  // momoInformation: "Mobile Money Information",
  // hastag: "#",
  // amount: "Amount",
  // amountTobeTransferred: "Amount to be Transferred",
  // newTransfer: "New Transfer",
  // fee: "Fee",
  // rate: "Rate",
  // vat: "VAT",
  // receiver_firstname: "Receiver First Name",
  // receiver_middlename: "Receiver Middle Name",
  // receiver_lastname: "Receiver Last Name",
  // receiver_phoneno: "Receiver Phone no",
  // address: "Address",
  // sender_country: "Sender country",
  // receiver_country: "Receiver country",
  // currency: "Currency",
  // receiver_momoId: "Receiver Mobile Money",
  // receiver_momo_phoneno: "Receiver Mobile Money Number",
  // transferId: "Transfer Id",
  // status: "Status",
  // date: "Date",
  // transferFee: "Transfer Fee",
  // exchangeRate: "Exchange Rate",
  // vat: "Vat",
  // senderCountry: "Sender Country",
  // totalAmount: "Total Amount",
  // amountPaidInLocalCcy: "Amount Paid",
  // comment: "Comment",
  // momoName: "Mobile Money Name",
  // momoNo: "Mobile Money Number",
  // momoOperator: "Mobile Money Operator",
  // senderFirstname: "Sender First Name",
  // senderLastName: "Sender Last Name",
  // senderMiddleName: "Sender Middle Name",
  // senderEmail: "Sender Email",
  // senderPhone: "Sender Phone",
  // senderTitle: "Sender Title",
  // senderGender: "sender Gender",
  // address: "Address",
  // gender: "Gender",
  // currency: "Currency",
  // maker: "Created by",
  // makerDate: "Created at",
  // checker: "Updated by",
  // checkerDate: "Updated at",
  // updateBtn: "Update",
  // maximumOfHeightCharacters: "Maximum of 8 characters",
  // minimumOfHeightCharacters: "Minimum of 8 characters",
  // noMoreHThanHeightCharacter: "There must be at least 8 characters",
  // receiveTransfer: "Receive Transfer",
  // email: "Email",
  // update: "Update Transfer",
  // newTransferBtn: "New Transfer",
  // recordStatus: "Record Status",
  // message: "Message",
  // makePaymentBtn:"I've made the deposit",
  // gotItBtn:"Got it",
  // pending: "Pending",
  // processing: "Processing",
  // completed: "Completed",
  // rejected: "Rejected",
  // email: "Email",
  // sender: "Sender",
  // receiver: "Receiver",
  // receiveAmount:"Receive Amount",
  // depositStatus: "Deposit Status",
};

/*!*******************************************************************************!
    !*** AUTH STATIC CONFIG                                                      ***!
    \*******************************************************************************/
export const staticConfig = {
  handleFlgStatus: (status) => {
    switch (status.toLowerCase()) {
      case "pending":
      case "new":
        return (
          <Tag icon={<ClockCircleOutlined />} color="warning">
            {status}
          </Tag>
        );
      case "processing":
        return (
          <Tag icon={<SyncOutlined spin />} color="processing">
            {status}
          </Tag>
        );
      case "completed":
        return (
          <Tag icon={<CheckCircleOutlined />} color="success">
            {status}
          </Tag>
        );
      case "rejected":
      case "cancelled":
        return (
          <Tag icon={<CloseCircleOutlined />} color="error">
            {status}
          </Tag>
        );
      default:
        return status;
    }
  },
};
