import React from "react";

//ANT DESIGN IMPORTS
import { Col, Form, Input, Select } from "antd";

//PROJECT IMPORTS
import { BOM } from "../../../services/definedMethod";
import { labelConfig } from "../recipient.config";

const MobileFields = (props) => {
  const { momoData } = props;
  return (
    <>
      <Col span={24} style={{ padding: "0px 8px" }}>
        <Form.Item
          label={`${labelConfig.payout}`}
          name="momoId"
          rules={[
            {
              required: true,
              message: BOM.CheckReqFld(labelConfig.payout),
            },
          ]}
        >
          <Select
            filterOption={(input, option) =>
              (option?.label?.toLowerCase() ?? "").includes(
                input?.toLowerCase()
              )
            }
            size="large"
            placeholder={BOM.handlePlaceholderMsg(
              `${labelConfig.payout} *`,
              "select"
            )}
            options={momoData}
            showSearch
          />
        </Form.Item>
      </Col>
    </>
  );
};

export default MobileFields;
