import React from "react";

//ANT DESIGN IMPORTS
import {
  Col,
  Form,
  Input,
  Select,
} from "antd";

//PROJECT IMPORTS
import { BOM } from "../../../services/definedMethod";
import { labelConfig } from "../recipient.config";

const CryptoFields = (props) => {
    return (
      <>
        <Col span={24} style={{ padding: "0px 8px" }}>
          <Form.Item
            label={labelConfig.walletName}
            name="rcpCryptoWalletName"
            rules={[
              {
                required: true,
                message: BOM.CheckReqFld(labelConfig.walletName),
              },
            ]}
          >
            <Select
              filterOption={(input, option) =>
                (option?.label?.toLowerCase() ?? "").includes(
                  input?.toLowerCase()
                )
              }
              size="large"
              placeholder={BOM.handlePlaceholderMsg(
                `${labelConfig.walletName} *`,
                "select"
              )}
              options={[
                { label: "Bitcoin (BTC)", value: "Bitcoin (BTC)" },
                { label: "Tether (USDT)", value: "Tether (USDT)" },
              ]}
              showSearch
            />
          </Form.Item>
        </Col>
        <Col span={24} style={{ padding: "0px 8px" }}>
          <Form.Item
            label={labelConfig.walletAddress}
            name="rcpCryptoWalletAddress"
            rules={[
              {
                required: true,
                message: BOM.CheckReqFld(labelConfig.walletAddress),
              },
            ]}
          >
            <Input
              maxLength={40}
              placeholder={BOM.handlePlaceholderMsg(
                `${labelConfig.walletAddress} *`
              )}
            />
          </Form.Item>
        </Col>
      </>
    );
  };

export default CryptoFields