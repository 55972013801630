import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { notifInitialization } from "./services/definedMethod";

const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

root.render(
    <App />
);

// Initialize notiflix popup
notifInitialization();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
