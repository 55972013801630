export default {
  isProduction: true,
  baseURL: {
    dev: process.env.REACT_APP_BASE_URL_DEVELOPMENT,
    prod: process.env.REACT_APP_BASE_URL_PRODUCTION,
  },
  paymentGatway: {
    sandbox: {
      url: process.env.REACT_APP_SANDBOX_URL,
      merchantId: process.env.REACT_APP_SANDBOX_MERCHANT_ID,
      merchantKey: process.env.REACT_APP_SANDBOX_MERCHANT_KEY,
    },
    live: {
      url: process.env.REACT_APP_LIVE_URL,
      merchantId: process.env.REACT_APP_LIVE_MERCHANT_ID,
      merchantKey: process.env.REACT_APP_LIVE_MERCHANT_KEY,
    },
  },
  apiSettings: {
    headers: {
      Authorization: null,
    },
  },
};
