import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Notiflix from "notiflix";

//ANT DESIGN IMPORTS
import {
  Row,
  Col,
  Card,
  Statistic,
  Button,
  Descriptions,
  Timeline,
  Divider,
  message,
  Spin,
  Typography,
  List,
} from "antd";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  EyeOutlined,
} from "@ant-design/icons";

//PROJECT IMPORTS
import { BOM } from "../../../services/definedMethod";
import cnt from "../../../services/definedConstant";
import { apiConfig, labelConfig, staticConfig } from "../transfer.config";
import Crypto from "../../../assets/images/GdeG_crypto.jpg";


const { Text, Paragraph } = Typography;
const DisplayCurrentTransfer = (props) => {
  const navigate = useNavigate();
  const { profileId, token } = props;
  const [transferList, setTransferList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [transferDetail, setTransferDetail] = useState({});
  const [bankDetails, setBankDetails] = useState([]);
  const [momoDetails, setmomoDetails] = useState([]);
  const [cryptoDetails, setCryptoDetails] = useState([]);
  const [toSouthAfrica, setToSouthAfrica] = useState(false);
  const [transferStatus, setTransferStatus] = useState({
    isPending: false,
    isPaid: false,
    isReady: false,
    isCompleted: false,
  });

  useEffect(() => {
    getTransfer();
  }, []);
  const getTransfer = () => {
    let body = {
      profileId,
      status: "all",
      token,
    };
    setIsLoading(true);
    BOM.FetchReqAction(body, apiConfig.GetTransfer, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err?.errorData[0]);
        setIsLoading(false);
      } else {
        const objList = res.transferListing;
        setTransferList(objList);

        if (objList?.length !== 0) getDetailById(objList[0].TRF_ID);

        //STOP LOAD
        setIsLoading(false);
      }
    });
  };
  const getDetailById = (transferId) => {
    // console.log(transferId);

    let body = {
      profileId,
      transferId,
      token,
    };

    setIsLoading(true);
    BOM.FetchReqAction(body, apiConfig.GetTransferDetail, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err?.errorData[0]);
        setIsLoading(false);
      } else {
        const objDetail = res.transferDetail;
        setTransferDetail(objDetail);
        setToSouthAfrica(
          objDetail.RCP_COUNTRY_SORTNAME?.toLowerCase() === "za" ? true : false
        );
        //handel bank and mobile info
        setBankDetails([
          {
            label: "Bank",
            children: objDetail?.companyBankInfo?.BI_BANK_NAME,
          },

          {
            label: "Holder",
            children: objDetail?.companyBankInfo?.BI_HOLDER_NAME,
          },
          {
            label: "Account Type",
            children: objDetail?.companyBankInfo?.BI_ACCOUNT_TYPE,
          },
          {
            label: "Account Number",
            children: objDetail?.companyBankInfo?.BI_ACCOUNT_NUMBER,
          },
          {
            label: "Branch",
            children: objDetail?.companyBankInfo?.BI_BRANCH,
          },
          {
            label: "Reference",
            children: (
              <Text
                style={{
                  fontSize: 14,
                  color: "var(--primaryColor)",
                }}
              >
                {objDetail?.TRF_CODE}
              </Text>
            ),
          },
          {
            label: "Total Amount",
            children: (
              <>
                <Statistic
                  valueStyle={{
                    fontSize: 14,
                    color: "var(--primaryColor)",
                  }}
                  prefix={
                    toSouthAfrica
                      ? objDetail?.companyCurrency?.CUR_SYMBOL
                      : objDetail?.localCurrency?.CUR_SYMBOL
                  }
                  value={objDetail?.localTransferData?.totalAmount}
                />
              </>
            ),
          },
        ]);
        setmomoDetails([
          {
            label: "Mobile Money",
            children: objDetail?.companyBankInfo?.MOMO_NAME,
          },
          {
            label: "Full Name",
            children: objDetail?.companyBankInfo?.MOMO_HOLDER,
          },
          {
            label: "Phone Number",
            children: objDetail?.companyBankInfo?.MOMO_PHONE,
          },
          {
            label: "Reference",
            children: (
              <Text
                style={{
                  fontSize: 14,
                  color: "var(--primaryColor)",
                }}
              >
                {objDetail?.TRF_CODE}
              </Text>
            ),
          },
          {
            label: "Reference",
            children: (
              <Text
                style={{
                  fontSize: 14,
                  color: "var(--primaryColor)",
                }}
              >
                {objDetail?.TRF_CODE}
              </Text>
            ),
          },
          {
            label: "Total Amount",
            children: (
              <>
                <Statistic
                  valueStyle={{
                    fontSize: 14,
                    color: "var(--primaryColor)",
                  }}
                  prefix={objDetail?.companyCurrency?.CUR_SYMBOL}
                  value={objDetail?.TRF_TOTAL_AMOUNT}
                />
              </>
            ),
          },
        ]);
        setCryptoDetails([
          {
            title: "Scan to Pay",
            description: <img style={{ width: 200 }} src={Crypto} />,
          },
          {
            title: "Copy the address to pay",
            description: <Paragraph copyable>{objDetail?.companyBankInfo?.CRYPTO_WALLET_ADDRESS}</Paragraph>,
          },
        ])
        // Handle status
        switch (objDetail?.TRF_STATUS?.toLowerCase()) {
          case "completed":
            setTransferStatus({
              ...transferStatus,
              isPaid: true,
              isReady: true,
              isCompleted: true,
            });
            break;
          case "paid":
            setTransferStatus({
              ...transferStatus,
              isPaid: true,
            });
            break;
          case "ready":
            setTransferStatus({
              ...transferStatus,
              isPaid: true,
              isReady: true,
            });
            break;
          default:
            setTransferStatus(transferStatus);
            break;
        }

        setIsLoading(false);
      }
    });
  };
  const bankDetailDisplay = () => {
    return (
      <Descriptions
        bordered
        size={"small"}
        extra={<></>}
        items={toSouthAfrica ? momoDetails : bankDetails}
      />
    );
  };
  const CryptoDetailDisplay = () => {
    return (
      <div style={{ marginTop: 50 }}>
        <Divider orientation="left">Or Pay by Crypto</Divider>
        <List
          grid={{
            gutter: 16,
            column: 1,
          }}
          dataSource={cryptoDetails}
          renderItem={(item) => (
            <List.Item>
              <Card title={item.title}>{item.description}</Card>
            </List.Item>
          )}
        />
      </div>
    );
  };
  const onCancelTransfer = (transferId) => {
    let confirmMessage =
      "<p>We have not received money for your current transfer request.&nbsp;";
    confirmMessage +=
      "<p>If you have already paid, please wait until you receive a confirmation email.</p>";
    confirmMessage +=
      "<p>If you haven't paid and want to cancel, click cancel below.</p>";
    Notiflix.Confirm.show(
      "Cancel Tranfer",
      `${confirmMessage}`,
      "Cancel Transfer",
      "Back",
      function () {
        // Yes
        let body = {
          profileId,
          transferId,
          status: "cancelled",
          token,
        };

        // console.log(body);
        // return;
        setIsLoading(true);
        BOM.FetchReqAction(body, apiConfig.CancelTransfer, (err, res) => {
          if (err) {
            const errorMsg = err?.errorData ? err?.errorData[0] : err.message;
            BOM.AlertMsg(cnt.DANGER, errorMsg);
            setIsLoading(false);
          } else {
            message.success(res.message);
            navigate("/manage-recipient");
            setIsLoading(false);
          }
        });
      },
      function () {
        // No button
      }
    );
  };
  return (
    <Row gutter={[24, 0]}>
      {/* <Skeleton loading={isLoading} active avatar /> */}
      {transferList.length !== 0 && (
        <Col span={24} md={24} className="mb-24">
          {/* <Card className="header-solid h-full"> */}
          <Spin spinning={isLoading} size="large">
            <span
              hidden={transferDetail?.TRF_STATUS?.toLowerCase() !== "pending"}
            >
              <Button
                shape="round"
                onClick={() => onCancelTransfer(transferDetail.TRF_ID)}
                danger
              >
                <CloseCircleOutlined /> {labelConfig.cancelTransfer}
              </Button>
            </span>
            <Button
              shape="round"
              onClick={() =>
                navigate("/detail-transfer", { state: transferDetail })
              }
              default
              style={{ margin: 10 }}
            >
              <EyeOutlined /> {labelConfig.viewTransfer}
            </Button>
            <Divider />
            <p style={{ fontSize: 18 }}>
              <img
                className="country-flag"
                src={transferDetail.RCP_COUNTRY_FLAG}
              />
              &nbsp;
              {`${transferDetail.RCP_FIRSTNAME} ${transferDetail.RCP_LASTNAME} - ${transferDetail.PAYMENT_TYPE_NAME}`}
            </p>
            <Timeline
              //   mode="left"
              items={[
                {
                  color: "#000",
                  children: (
                    <p>{`Order Created on ${transferDetail?.recordStatus?.MAKER_DATE}`}</p>
                  ),
                },
                {
                  dot: (
                    <CheckCircleOutlined className="gg-current-transfer-icon" />
                  ),
                  children: (
                    <Card>
                      <Text>
                        <p style={{ fontSize: 18 }}>
                          We're just waiting for your{" "}
                          {`${toSouthAfrica ? "mobile" : "bank"}`} transfer
                        </p>
                        <p>
                          Please make{" "}
                          {`${toSouthAfrica ? "a payment" : "an instant EFT"}`}{" "}
                          using the details provided below within 1 hour.
                        </p>
                        <p>Remember no cash deposits.</p>
                        {/* <p>Order expires on 18/09/2024</p> */}
                      </Text>
                      {bankDetailDisplay()}

                      {CryptoDetailDisplay()}
                    </Card>
                  ),
                },
                {
                  dot: (
                    <CheckCircleOutlined
                      className={
                        transferStatus.isPaid
                          ? "gg-current-transfer-icon"
                          : "transaction-timeline"
                      }
                    />
                  ),
                  children: (
                    <span
                      className={
                        transferStatus.isPaid ? "" : "transaction-timeline"
                      }
                    >
                      Payment Received
                    </span>
                  ),
                },
                {
                  dot: (
                    <CheckCircleOutlined
                      className={
                        transferStatus.isReady
                          ? "gg-current-transfer-icon"
                          : "transaction-timeline"
                      }
                    />
                  ),
                  children: (
                    <span
                      className={
                        transferStatus.isReady ? "" : "transaction-timeline"
                      }
                    >
                      Ready for Collection
                    </span>
                  ),
                },
                {
                  dot: (
                    <CheckCircleOutlined
                      className={
                        transferStatus.isCompleted
                          ? "gg-current-transfer-icon"
                          : "transaction-timeline"
                      }
                    />
                  ),
                  children: (
                    <>
                      <p
                        className={
                          transferStatus.isCompleted
                            ? ""
                            : "transaction-timeline"
                        }
                      >
                        Transfer Completed
                      </p>
                      <small
                        className={
                          transferStatus.isCompleted
                            ? ""
                            : "transaction-timeline"
                        }
                      >
                        Received by{" "}
                        <b>{`${transferDetail.RCP_FIRSTNAME} ${transferDetail.RCP_LASTNAME}`}</b>{" "}
                        {transferDetail.CHECKER_DATE &&
                          `on ${transferDetail.CHECKER_DATE}`}
                      </small>
                    </>
                  ),
                },
              ]}
            />
          </Spin>
          {/* </Card> */}
        </Col>
      )}
      {!isLoading && transferList.length === 0 && (
        <Col span={24}>There is no current transfer.</Col>
      )}
    </Row>
  );
};

export default DisplayCurrentTransfer;
