import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

// PROTECTED ROUTE IMPORT
import RouteGuard from "./RouteGuard";
import Main from "../components/layout/Main";

// MODULE ROUTE IMPORTS
import authRoute from "../pages/authentication/auth.route";
import defaultRoute from "./default.route";
import profileRoute from "../pages/profile/profile.route";
import transfertRoute from "../pages/transfer/transfert.route";
import recipientRoute from "../pages/recipient/recipient.route";
import adminRoute from "../pages/admin/admin.route";
import configurationRoute from "../pages/configuration/configuration.route";

// MERGED ROUTES
const allRoutes = [
  ...authRoute,
  ...defaultRoute,
  ...profileRoute,
  ...transfertRoute,
  ...recipientRoute,
  ...adminRoute,
  ...configurationRoute
];

export default () => {
  return (
    <BrowserRouter>
    {/* <Main> */}
      <Routes>
        {allRoutes.map((item, index) => (
          <Route
            key={index}
            path={item.path}
            element={
              item.isProtected ? (
                <RouteGuard component={item.component} />
              ) : (
                <item.component />
              )
            }
          />
        ))}
      </Routes>
      {/* </Main> */}
    </BrowserRouter>
  );
};
