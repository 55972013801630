import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Notiflix from "notiflix";

//ANT DESIGN IMPORTS
import {
  Row,
  Col,
  Card,
  Button,
  Descriptions,
  Tag,
  Typography,
  Skeleton
} from "antd";
import {
    DoubleRightOutlined
} from "@ant-design/icons";

//PROJECT IMPORTS
import { BOM } from "../../../services/definedMethod";
import cnt from "../../../services/definedConstant";
import { apiConfig, labelConfig, staticConfig } from "../transfer.config";

const { Text } = Typography;

const DisplayCompletedTransfer = (props) => {
    const navigate = useNavigate();
    const { profileId, token } = props;
    const [transferList, setTransferList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
  
    useEffect(() => {
      getTransfer();
    }, []);
    const getTransfer = () => {
      let body = {
        profileId,
        status: "completed",
        token,
      };
      setIsLoading(true);
      BOM.FetchReqAction(body, apiConfig.GetTransfer, (err, res) => {
        if (err) {
          BOM.AlertMsg(cnt.DANGER, err?.errorData[0]);
          setIsLoading(false);
        } else {
          const objList = res.transferListing;
          setTransferList(objList);
          //STOP LOAD
          setIsLoading(false);
        }
      });
    };
    return (
      <>
        <Row gutter={[24, 24]}>
          <Skeleton loading={isLoading} active avatar />
  
          {transferList.map((item, index) => (
            <Col span={24} key={index}>
              <Card
                className="card-billing-info"
                bordered="false"
                onClick={() => navigate("/detail-transfer", { state: item })}
              >
                <Descriptions
                  // bordered
                  size={"small"}
                  extra={<></>}
                  items={[
                    {
                      label: "#",
                      children: index + 1,
                    },
                    {
                      label: labelConfig.date,
                      children: item.MAKER_DATE,
                    },
                    {
                      label: labelConfig.status,
                      children: (
                        <>{staticConfig.handleFlgStatus(item.TRF_STATUS)}</>
                      ),
                    },
                    {
                      label: labelConfig.trnRefenceCode,
                      children: <Tag>{item.TRF_CODE}</Tag>,
                    },
                    {
                      label: labelConfig.receiver,
                      children: item.RECIPIENT_FULLNAME,
                    },
                    {
                      label: labelConfig.amount,
                      children: (
                        <Tag>{`${item.CURRENCY_SYMBOL} ${item.TRF_AMOUNT}`}</Tag>
                      ),
                    },
                    {
                      label: `${labelConfig.country} ( ${item.RCP_COUNTRY_SORTNAME} )`,
                      children: (
                        <>
                          <img
                            className="country-flag"
                            src={item.RCP_COUNTRY_FLAG}
                            title={item.RCP_COUNTRY_NAME}
                          />
                        </>
                      ),
                    },
                  ]}
                />
                <div className="col-action">
                  <Button type="link" className="darkbtn">
                    <DoubleRightOutlined />
                    VIEW MORE
                  </Button>
                </div>
              </Card>
            </Col>
          ))}
  
          <Col span={24}>
            {!isLoading &&
              transferList.length === 0 &&
              `There is no transfer history.`}
          </Col>
        </Row>
      </>
    );
  };

export default DisplayCompletedTransfer