import { legacy_createStore, applyMiddleware, compose } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import { encryptTransform } from "redux-persist-transform-encrypt";
import storage from "redux-persist/lib/storage";
import reduxThunk from "redux-thunk";
import rootReducer from "./reducers"


const persistConfig = {
    key: "root",
    storage,
    transforms: [
      encryptTransform({
        secretKey: "my-super-secret-key",
        onError: function (error) {
          console.log(error);
        },
      }),
    ],
  };
  const persistedReducer = persistReducer(persistConfig, rootReducer);
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const store = legacy_createStore(
    persistedReducer,
    composeEnhancers(applyMiddleware(reduxThunk))
  );
  const persistor = persistStore(store);
  
  export { store, persistor };
