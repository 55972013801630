import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useDispatch, connect } from "react-redux";

//ANT DESIGN IMPORTS
import {
  Layout,
  Menu,
  Button,
  Row,
  Col,
  Typography,
  Form,
  Input,
  message,
  Card,
} from "antd";

//PROJECT IMPORTS
import { SignOutAction } from "../../redux/actions/authAction";
import cnt from "../../services/definedConstant";
import { BOM } from "../../services/definedMethod";
import { apiConfig, labelConfig, staticConfig } from "./auth.config";
import logo from "../../assets/images/logoColorAnimate.gif";
import { WhatsappFacility } from "../../components/Facility";

const { Title } = Typography;
const { Header, Footer, Content } = Layout;

const ResetPassword = (props) => {
  const { SignOutAction } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const param = useParams();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    SignOutAction();
  }, []);

  const onSubmit = (formValues) => {
    formValues.token = param.token;

    const body = {
      ...formValues,
    };

    // console.log(body);
    // return
    setIsLoading(true);
    BOM.SessionLessReqAction(body, apiConfig.ResetPassword, (err, res) => {
      if (err) {
        const errorMsg = err?.errorData ? err?.errorData[0] : err.message
        BOM.AlertMsg(cnt.DANGER, errorMsg);
        // message.error(errorMsg);
        setIsLoading(false);
      } else {
        BOM.AlertMsg(cnt.SUCCESS, res.message);
        navigate("/sign-in");
      }
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <>
    <WhatsappFacility/>
      <Layout className="layout-default layout-signin">
        <Content className="signin">
          <Row gutter={[24, 0]} justify="space-around">
            <Card
              style={{
                marginTop: "20px",
                width: 400,
                // height:500,
                marginLeft: "auto",
                marginRight: "auto",
                boxShadow: "0px 0px 30px 10px rgba(0,0,0,0.1)",
                borderRadius: 25,
              }}
            >
              <Col
                className="sign-img"
                xs={{ span: 24, offset: 0 }}
                lg={{ span: 24, offset: 0 }}
                md={{ span: 24 }}
              >
                <img src={logo} alt="" />
                {/* <Title className="mb-15">Sign In</Title> */}
                <Title className="font-regular text-muted" level={5}>
                  {labelConfig.resetPassword}
                </Title>
                <Form
                  onFinish={onSubmit}
                  onFinishFailed={onFinishFailed}
                  layout="vertical"
                  className="row-col"
                >
                  <Form.Item
                    className="username"
                    label={labelConfig.newPassword}
                    name={"newPassword"}
                    rules={[
                      {
                        required: true,
                        message: BOM.handleReqFldMsg(labelConfig.newPassword),
                      },
                      {
                        min: 8,
                        message: "Minimum password length is 8 characters",
                      },
                      {
                        max: 12,
                        message: "Maximum password length is 12 characters",
                      },
                      {
                        pattern: BOM.RegExValidation("password"),
                        message:
                          "At least one uppercase, lowercase, digit, and special character.",
                      },
                    ]}
                    hasFeedback
                  >
                    <Input.Password
                      placeholder={BOM.handlePlaceholderMsg(
                        labelConfig.newPassword
                      )}
                      style={{ padding: "0 10px" }}
                    />
                  </Form.Item>
                  <Form.Item
                    className="username"
                    name="confirmNewPassword"
                    label={labelConfig.confirmPassword}
                    rules={[
                      {
                        required: true,
                        message: BOM.handleReqFldMsg(
                          labelConfig.confirmPassword
                        ),
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (
                            !value ||
                            getFieldValue("newPassword") === value
                          ) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error(
                              "The new password you entered does not match."
                            )
                          );
                        },
                      }),
                    ]}
                    hasFeedback
                  >
                    <Input.Password
                      placeholder={BOM.handlePlaceholderMsg(
                        labelConfig.confirmPassword
                      )}
                      style={{ padding: "0 10px" }}
                    />
                  </Form.Item>
                  <Form.Item>
                    <Button
                      htmlType="submit"
                      className="of-primaryBtnColor"
                      loading={isLoading}
                    >
                      {labelConfig.submitBtn}
                    </Button>
                  </Form.Item>
                  <p className="font-semibold text-muted">
                    Is the link to reset expired?{" "}
                    <Link to="/forgot-password" className="text-dark font-bold">
                      Request a new one!
                    </Link>
                  </p>
                </Form>
              </Col>
            </Card>
            {/* <Col
              className="sign-img"
              style={{ padding: 12 }}
              xs={{ span: 24 }}
              lg={{ span: 12 }}
              md={{ span: 12 }}
            >
              <img src={logo} alt="" />
            </Col> */}
          </Row>
        </Content>
        <Footer>
          <p className="copyright">
            {" "}
            Copyright © 2024 GdeG by <a href="#pablo">nAcomoda Team</a>.{" "}
          </p>
        </Footer>
      </Layout>
    </>
  );
};
const mapDispatchToProps = { SignOutAction };
export default connect(null, mapDispatchToProps)(ResetPassword);
