export default {
  username: "Username",
  password: "Password",
  btnLogin: "Login",
  forgotpass: "Forgot Password",
  createAccount: "Register Now",
  currentPwd: "Current Password",
  newPwd: "New Password",
  currentPwd: "Current Password",
  confirmPwd: "Confirm Password",
  resetPwd: "Reset Password",
  changePwd: "Change Password",
  changePwdInfo: "Change Password Information",
  proceedBtn: "Proceed",
  fldWithAsteRq: "Fields with ( * ) are required",
  isRequired: " is required",
  pleaseSelect: "Please Select ",
  pleaseEnter: "Please Enter ",
   internetConnection:
    "Could not connect to the server. Please check your network",
  smthWentWrong:
    "We were unable to obtain timely feedback on your request. Please retry.",
};
