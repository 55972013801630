import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

//ANT DESIGN IMPORTS
import {
  Row,
  Col,
  Card,
  Divider,
  Button,
  Form,
  Input,
  Typography,
  Select,
  InputNumber,
} from "antd";

//PROJECT IMPORTS
import Main from "../../components/layout/Main";
import { BOM } from "../../services/definedMethod";
import cnt from "../../services/definedConstant";
import { apiConfig, labelConfig } from "./transfer.config";

const UpdateTransfer = (props) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [useForm] = Form.useForm();
  const { userData } = props;
  const { PROFILE_ID, COUNTRY_ID, session } = userData;

  const [transferDetail, setTransferDetail] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [amountOptions, setAmountOptions] = useState(0);
  const [template, setTemplate] = useState();
  const [feeTransfer, setFeeTransfer] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [countryData, setCountryData] = useState([]);
  const [momoData, setMomoData] = useState([]);

  useEffect(() => {
    getDetailById();
    getTemplate();
    getCountry();
    getMomo();
    console.log(props);
  }, []);
  const getDetailById = () => {
    setIsLoading(true);

    let body = {
      profileId: PROFILE_ID,
      transferId: state.TRANSFER_ID,
      token: session,
    };

    BOM.FetchReqAction(body, apiConfig.GetTransferDetail, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err.message);
        setIsLoading(false);
      } else {
        const objDetail = res.transferDetail;
        setTransferDetail(objDetail);

        useForm.setFieldsValue({
          // amount: objDetail.TRANSFER_AMOUNT,
          // totalAmount: objDetail.TRANSFER_TOTAL_AMOUNT,
          // fee: objDetail.TRANSFER_FEE,
          // currency: objDetail.CURRENCY_SYMBOL,
          receiverFirstname: objDetail.TRANSFER_RECEIVER_FIRSTNAME,
          receiverLastname: objDetail.TRANSFER_RECEIVER_LASTNAME,
          receiverPhoneNo: objDetail.TRANSFER_RECEIVER_PHONE,
          receiverCountry: objDetail.TRANSFER_RECEIVER_COUNTRY,
          momoId: objDetail.MOMO_ID,
          momoPhoneNo: objDetail.TRANSFER_RECEIVER_MOMO_PHONE,
        });
        // setFeeTransfer(objDetail.TRANSFER_FEE);
        // setTotalAmount(objDetail.TRANSFER_TOTAL_AMOUNT);
        setIsLoading(false);
      }
    });
  };
  const getTemplate = () => {
    const objTemplate = {
      limitAmount: 300,
      feeRate: 7.5,
      currency: {
        id: 6,
        name: "US Dollar",
        code: "$",
        symbol: "USD",
      },
    };
    setTemplate(objTemplate);
    generateAmount(objTemplate.limitAmount);
  };
  const generateAmount = (length) => {
    const tempAmount = [];
    for (let index = 10; index <= length; index++) {
      // console.log(index);
      tempAmount.push({
        value: index,
        label: index,
      });
    }
    setAmountOptions(tempAmount);
  };
  const onSelectAmount = (param) => {
    const fee = (param * parseFloat(template?.feeRate)) / 100;
    setFeeTransfer(fee);
    setTotalAmount(fee + param);

    useForm.setFieldsValue({
      totalAmount: fee + param,
      fee: fee,
      currency: template?.currency?.id,
    });
  };
  const getCountry = (status) => {
    let body = {
      profileId: PROFILE_ID,
      status: "activated",
      token: session,
    };
    setIsLoading(true);
    BOM.FetchReqAction(body, apiConfig.GetCountry, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err.message);
        setIsLoading(false);
      } else {
        const objList = res.countryListing;
        let tempCountry = [];

        objList
          ?.filter((item) => item.COUNTRY_ID !== COUNTRY_ID)
          .forEach((element) => {
            tempCountry.push({
              value: element.COUNTRY_ID,
              label: `${element.COUNTRY_NAME} ( ${element.COUNTRY_SORTNAME} )`,
            });
          });

        setCountryData(tempCountry);
        //STOP LOAD
        setIsLoading(false);
      }
    });
  };
  const getMomo = (status) => {
    let body = {
      profileId: PROFILE_ID,
      status: "activated",
      token: session,
    };
    setIsLoading(true);
    BOM.FetchReqAction(body, apiConfig.GetMomo, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err.message);
        setIsLoading(false);
      } else {
        const objList = res.momoListing;
        let tempMomo = [];

        objList?.forEach((element) => {
          tempMomo.push({
            value: element.MOMO_ID,
            label: `${element.MOMO_NAME} ( ${element.MOMO_OPERATOR} )`,
          });
        });

        setMomoData(tempMomo);
        //STOP LOAD
        setIsLoading(false);
      }
    });
  };
  const onSubmit = (formValues) => {
    formValues.senderCountry = COUNTRY_ID;
    formValues.receiverPhoneNo += "";
    formValues.momoPhoneNo += "";

    let body = {
      profileId: PROFILE_ID,
      transferId: state.TRANSFER_ID,
      ...formValues,
      token: session,
    };
    // console.log(body);
    // return;
    setIsLoading(true);
    BOM.FetchReqAction(body, apiConfig.UpdateTransfer, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err.message);
        setIsLoading(false);
      } else {
        BOM.AlertMsg(cnt.SUCCESS, res.message);
        navigate("/manage-transfer");
        setIsLoading(false);
      }
    });
  };
  return (
    <Main>
      <Row gutter={[24, 0]}>
        <Col span={24} md={24} className="mb-24">
          <Card className="header-solid h-full">
            <Form
              form={useForm}
              layout="vertical"
              autoComplete="off"
              onFinish={onSubmit}
            >
              <Divider orientation="left">
                {labelConfig.generalInformation}
              </Divider>

              <Row>
                <Col span={24} style={{ padding: "0px 8px" }}>
                  <Form.Item label={labelConfig.amount}>
                    <Typography>
                      <pre style={{ margin: 0 }}>
                        {transferDetail?.TRANSFER_AMOUNT}{" "}
                        {transferDetail?.CURRENCY_SYMBOL}
                      </pre>
                    </Typography>
                  </Form.Item>
                </Col>
                <Col span={24} style={{ padding: "0px 8px" }}>
                  <Form.Item label={labelConfig.fee}>
                    <Typography>
                      <pre style={{ margin: 0 }}>
                        {transferDetail?.TRANSFER_FEE}{" "}
                        {transferDetail?.CURRENCY_SYMBOL}
                      </pre>
                    </Typography>
                  </Form.Item>
                </Col>
                <Col span={24} style={{ padding: "0px 8px" }}>
                  <Form.Item label={labelConfig.totalAmount}>
                    <Typography>
                      <pre style={{ margin: 0 }}>
                        {transferDetail?.TRANSFER_TOTAL_AMOUNT}{" "}
                        {transferDetail?.CURRENCY_SYMBOL}
                      </pre>
                    </Typography>
                  </Form.Item>
                </Col>

                <Col span={24} style={{ padding: "0px 8px" }}></Col>
                <Divider orientation="left">
                  {labelConfig.receiverInformation}
                </Divider>
                <Col span={24} style={{ padding: "0px 8px" }}>
                  <Form.Item
                    label={labelConfig.firstName}
                    name="receiverFirstname"
                    rules={[
                      {
                        required: true,
                        message: BOM.CheckReqFld(labelConfig.firstName),
                      },
                    ]}
                  >
                    <Input
                      maxLength={40}
                      placeholder={BOM.handlePlaceholderMsg(
                        `${labelConfig.firstName} *`
                      )}
                    />
                  </Form.Item>
                </Col>
                <Col span={24} style={{ padding: "0px 8px" }}>
                  <Form.Item
                    label={labelConfig.lastName}
                    name="receiverLastname"
                    rules={[
                      {
                        required: true,
                        message: BOM.CheckReqFld(labelConfig.lastName),
                      },
                    ]}
                  >
                    <Input
                      maxLength={40}
                      placeholder={BOM.handlePlaceholderMsg(
                        `${labelConfig.lastName} *`
                      )}
                    />
                  </Form.Item>
                </Col>
                <Col span={24} style={{ padding: "0px 8px" }}>
                  <Form.Item
                    label={labelConfig.phoneNo}
                    name="receiverPhoneNo"
                    rules={[
                      {
                        required: true,
                        message: BOM.CheckReqFld(labelConfig.phoneNo),
                      },
                    ]}
                  >
                    <InputNumber
                      className="inputFieldNumber"
                      maxLength={20}
                      placeholder={BOM.handlePlaceholderMsg(
                        `${labelConfig.phoneNo} *`
                      )}
                    />
                  </Form.Item>
                </Col>
                <Col span={24} style={{ padding: "0px 8px" }}>
                  <Form.Item label={labelConfig.country}>
                    <Typography>
                      <pre style={{ margin: 0 }}>
                        {transferDetail?.TRANSFER_RECEIVER_COUNTRY}
                      </pre>
                    </Typography>
                  </Form.Item>
                </Col>
                <Divider orientation="left">
                  {labelConfig.momoInformation}
                </Divider>

                <Col span={24} style={{ padding: "0px 8px" }}>
                  <Form.Item
                    label={`${labelConfig.momoName}`}
                    name="momoId"
                    rules={[
                      {
                        required: true,
                        message: BOM.CheckReqFld(labelConfig.momoName),
                      },
                    ]}
                  >
                    <Select
                      size="large"
                      placeholder={BOM.handlePlaceholderMsg(
                        `${labelConfig.momoName} *`
                      )}
                      options={momoData}
                      showSearch
                    />
                  </Form.Item>
                </Col>
                <Col span={24} style={{ padding: "0px 8px" }}>
                  <Form.Item
                    label={labelConfig.momoNo}
                    name="momoPhoneNo"
                    rules={[
                      {
                        required: true,
                        message: BOM.CheckReqFld(labelConfig.momoNo),
                      },
                    ]}
                  >
                    <InputNumber
                      className="inputFieldNumber"
                      maxLength={20}
                      placeholder={BOM.handlePlaceholderMsg(
                        `${labelConfig.momoNo} *`
                      )}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item>
                <Button
                  className="of-primaryBtnColor"
                  htmlType="submit"
                  loading={isLoading}
                >
                  {labelConfig.updateBtn}
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </Main>
  );
};

export default UpdateTransfer;
