import AdminManageTransfer from "./AdminManageTransfer";
import AdminDetailTransfer from "./AdminDetailTransfer";
// import AdminUpdateTransfer from "./AdminUpdateTransfer";
// import AdminSearchTransfer from "./AdminSearchTransfer";
import ManageProfile from "./ManageProfile";
import AdminDashboard from "./AdminDashboard";


export default [
  {
    path: "/admin-dashboard",
    component: AdminDashboard,
    isProtected: true,
  },
  {
    path: "/admin-manage-transfer",
    component: AdminManageTransfer,
    isProtected: true,
  },
  {
    path: "/admin-detail-transfer",
    component: AdminDetailTransfer,
    isProtected: true,
  },
  // {
  //   path: "/admin-update-transfer",
  //   component: AdminUpdateTransfer,
  //   isProtected: true,
  // },
  // {
  //   path: "/admin-search-transfer",
  //   component: AdminSearchTransfer,
  //   isProtected: true,
  // },
  {
    path: "/manage-profile",
    component: ManageProfile,
    isProtected: true,
  }
];
