//project imports
import cnt from "../../services/definedConstant";

const INITIAL_STATE = {
  userData: false,
  lockscreenData: {
    userName: null,
    profileFirstName: null,
    profileLastName: null,
  },
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case cnt.SIGN_IN:
      return {
        ...state,
        userData: payload,
        lockscreenData: {
          username: payload.USERNAME,
          fullName: payload.USER_FIRSTNAME + " " + payload.USER_LASTNAME,
        },
      };
    case cnt.SIGN_OUT:
      return { ...INITIAL_STATE };
    case cnt.LOCKSCREEN:
      return {
        ...state,
        userData: false,
        configData: {},
      };
    default:
      return state;
  }
};
