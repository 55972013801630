import { BOM } from "../../services/definedMethod";
import {
  Tag,
  message,
} from "antd";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  SyncOutlined,
  ClockCircleOutlined
} from "@ant-design/icons";

/*!*******************************************************************************!
  !*** API CONFIG                                                              ***!
  \*******************************************************************************/
export const apiConfig = {
  GetExchangeRateTemplate: BOM.baseUrl("getExchangeRateTemplate"),
};

/*!*******************************************************************************!
  !*** AUTH LABEL CONFIG                                                       ***!
  \*******************************************************************************/
export const labelConfig = {
  country: "Country",
  amount: "Amount",
  sendTo: "Send To",
  sendAmount: "Send Amount",
  sendMoney: "Send Money",
  // transferInformation: "Transfer Information",
  // generalInformation: "General Information",
  // senderInformation: "Sender Information",
  // receiverInformation: "Recipient Information",
  // momoInformation: "Mobile Money Information",
  // hastag: "#",
  // amountTobeTransferred: "Amount Received",
  // newTransfer: "New Transfer",
  // fee: "Fee",
  // rate: "Rate",
  // vat: "VAT",
  // receiver_firstname: "Recipient First Name",
  // receiver_middlename: "Recipient Middle Name",
  // receiver_lastname: "Recipient Last Name",
  // receiver_phoneno: "Recipient Phone no",
  // address: "Address",
  // sender_country: "Sender country",
  // receiver_country: "Recipient country",
  // currency: "Currency",
  // receiver_momoId: "Recipient Mobile Money",
  // receiver_momo_phoneno: "Recipient Mobile Money Number",
  // transferId: "Transfer Id",
  // status: "Status",
  // date: "Date",
  // transferFee: "GdeG's Fee",
  // exchangeRate: "Exchange Rate",
  // vat: "Vat",
  // trnRefenceCode: "Reference Code",
  // receiverFirstName: "Recipient First Name",
  // receiverLastName: "Recipient Last Name",
  // receiverMiddleName: "Recipient Middle Name",
  // receiverPhoneNo: "Recipient Phone Number",
  // receiverAddress: "Recipient Address",
  // receiverCountry: "Recipient Country",
  // transferRefenceCode: "Transfer Refence Code",
  // senderCountry: "Sender Country",
  // totalAmount: "Total Amount",
  // amountPaidInLocalCcy: "Amount Paid",
  // comment: "Comment",
  // momoName: "Mobile Money Name",
  // momoNo: "Mobile Money Number",
  // momoOperator: "Mobile Money Operator",
  // senderFirstname: "Sender First Name",
  // senderLastName: "Sender Last Name",
  // senderMiddleName: "Sender Middle Name",
  // senderEmail: "Sender Email",
  // senderPhone: "Sender Phone",
  // senderTitle: "Sender Title",
  // senderGender: "sender Gender",
  // firstName: "First Name",
  // lastName: "Last Name",
  // middleName: "Middle Name",
  // phoneNo: "Phone Number",
  // address: "Address",
  // gender: "Gender",
  // currency: "Currency",
  // maker: "Created by",
  // makerDate: "Created at",
  // checker: "Updated by",
  // checkerDate: "Updated at",
  // submit: "Submit",
  // updateBtn: "Update",
  // maximumOfHeightCharacters: "Maximum of 8 characters",
  // minimumOfHeightCharacters: "Minimum of 8 characters",
  // noMoreHThanHeightCharacter: "There must be at least 8 characters",
  // receiveTransfer: "Receive Transfer",
  // email: "Email",
  // update: "Update Transfer",
  // newTransferBtn: "Send Money",
  // // newTransferBtn: "New Transfer",
  // recordStatus: "Record Status",
  // message: "Message",
  // makePaymentBtn:"I've made the deposit",
  // gotItBtn:"Got it",
  // pending: "Pending",
  // processing: "Processing",
  // completed: "Completed",
  // rejected: "Rejected",
  // email: "Email",
  // sender: "Sender",
  // receiver: "Recipient",
  // receiveAmount:"Receive Amount",
  // depositStatus: "Deposit Status",
  // reviewTrfOrder: "Review Transfer Order",
  // all: "all",
  // current: "Current",
  // recipientPayoutInformation: "Recipient Payout Information",
  // payoutMethod: "Payout Method",
  // payout: "Payout",
  // cancelTransfer: "Cancel Transfer",

};

/*!*******************************************************************************!
    !*** AUTH STATIC CONFIG                                                      ***!
    \*******************************************************************************/
export const staticConfig = {
  handleFlgStatus: (status) => {
    switch (status.toLowerCase()) {
      case "pending":
        return (
          <Tag icon={<ClockCircleOutlined />} color="warning">
            {status}
          </Tag>
        );
        case "ready":
        return (
          <Tag icon={<SyncOutlined spin />} color="processing">
            {status}
          </Tag>
        );
      case "completed":
        return (
          <Tag icon={<CheckCircleOutlined />} color="success">
            {status}
          </Tag>
        );
      case "cancelled":
        return (
          <Tag icon={<CloseCircleOutlined />} color="error">
            {status}
          </Tag>
        );
      default:
        return status;
    }
  },
};
