import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { NavLink, Link, useNavigate } from "react-router-dom";
import Notiflix from "notiflix";

//ANT DESIGN IMPORTS
import {
  Row,
  Col,
  Card,
  Statistic,
  Button,
  Typography,
  Descriptions,
  Tag,
  Radio,
  Skeleton,
  Input,
  Divider,
  Avatar,
} from "antd";
import {
  DoubleRightOutlined,
  ReloadOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";

//PROJECT IMPORTS
import Main from "../../components/layout/Main";
import { BOM } from "../../services/definedMethod";
import cnt from "../../services/definedConstant";
import { apiConfig, labelConfig, staticConfig } from "./admin.config";
import profilavatarMale from "../../assets/images/profile_pic_male.svg";
import profilavatarFemale from "../../assets/images/profile_pic_femal.svg";
import { BackToSpecificScreen } from "../../components/Facility";

const { Text } = Typography;
const { Meta } = Card;
const ManageProfile = (props) => {
  const navigate = useNavigate();
  const { userData } = props;
  const { PROFILE_ID, CO_ID, session,isAdmin } = userData;
  const [userList, setUserList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [statStatus, setStatStatus] = useState("");
  const [profileCount, setProfileCount] = useState({
    administrator: 0,
    customer: 0,
  });
  const [originalList, setOriginalList] = useState([]);

  useEffect(() => {
    if (!isAdmin) return navigate("/");
    
    getProfile("administrator");
  }, []);

  const getProfile = (criteria) => {
    let body = {
      profileId: PROFILE_ID,
      coId: CO_ID,
      criteria,
      token: session,
    };
    setIsLoading(true);
    BOM.FetchReqAction(body, apiConfig.GetProfile, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err?.errorData[0]);
        setIsLoading(false);
      } else {
        const objList = res.profileListing;
        setUserList(objList);
        setStatStatus(criteria);
        setOriginalList(objList);
        //STOP LOAD
        setIsLoading(false);

        switch (criteria.toLowerCase()) {
          case "administrator":
            return setProfileCount({
              ...profileCount,
              administrator: res.profileCount,
            });
          case "customer":
            return setProfileCount({
              ...profileCount,
              customer: res.profileCount,
            });
          default:
            return 0;
        }
      }
    });
  };
  const onChange = (e) => getProfile(e.target.value);
  function handleSearchInList(searchKey) {
    searchKey = searchKey.target.value;

    if (!searchKey) {
      setUserList(originalList);
      return;
    }
    const filterBySearch = originalList.filter(
      (item) =>
        item?.USERNAME.toLowerCase()?.includes(searchKey?.toLowerCase()) ||
        item?.USER_LASTNAME.toLowerCase()?.includes(searchKey?.toLowerCase()) ||
        item?.USER_FIRSTNAME.toLowerCase()?.includes(searchKey?.toLowerCase())
    );
    setUserList(filterBySearch);
  }
  return (
    <Main>
      <Row gutter={[24, 0]}>
        <Col span={24} md={24} className="mb-24">
        <BackToSpecificScreen route={"/admin-dashboard"}/>
          <Card
            className="header-solid h-full"
            bordered={false}
            extra={
              <>
                <Radio.Group
                  onChange={onChange}
                  defaultValue="pending"
                  className="radio-title-button"
                  size="small"
                  style={{ marginTop: "10px", marginBottom: "20px" }}
                >
                  <Radio.Button value="administrator">
                    {`${labelConfig.administrator.toUpperCase()} ( ${
                      profileCount.administrator
                    } )`}
                  </Radio.Button>
                  <Radio.Button value="customer">
                    {`${labelConfig.customer.toUpperCase()} ( ${
                      profileCount.customer
                    } )`}
                  </Radio.Button>
                </Radio.Group>
              </>
            }
          >
            <Col span={24} md={24} className="mb-24">
              <Input
                placeholder="Search by user Surname, Firstname, or username"
                onChange={handleSearchInList}
                allowClear
              />
            </Col>
            {!isLoading && userList.length === 0 ? (
              <Row>No {statStatus} user</Row>
            ) : (
              <Row gutter={[24, 24]}>
                <Skeleton loading={isLoading} active avatar />

                {userList.map((item, index) => (
                  <Col xs={24} sm={24} key={index}>
                    <Card
                      className="card-billing-info"
                      bordered="false"
                      // onClick={() =>
                      //   navigate("/admin-detail-user", { state: item })
                      // }
                    >
                      <Descriptions
                        // bordered
                        size={"small"}
                        extra={<></>}
                        items={[
                          {
                            // label: "#",
                            children: (
                              <>
                                {" "}
                                <Meta
                                  avatar={
                                    <Avatar
                                      size={50}
                                      shape="square"
                                      src={
                                        item?.USER_GENDER?.toLowerCase() ===
                                        "male"
                                          ? profilavatarMale
                                          : profilavatarFemale
                                      }
                                    />
                                  }
                                  // title="Card title"
                                  // description="This is the description"
                                />
                              </>
                            ),
                          },
                          {
                            label: labelConfig.username,
                            children: <Tag>{item.USERNAME}</Tag>,
                          },
                          {
                            label: labelConfig.title,
                            children: item.USER_TITLE,
                          },
                          {
                            label: labelConfig.lastname,
                            children: item.USER_LASTNAME,
                          },
                          {
                            label: labelConfig.firstname,
                            children: item.USER_FIRSTNAME,
                          },
                          {
                            label: labelConfig.email,
                            children: item.USER_EMAIL,
                          },
                          {
                            label: labelConfig.phone,
                            children: item.USER_PHONE,
                          },
                          {
                            label: labelConfig.gender,
                            children: item.USER_GENDER,
                          },
                          {
                            label: labelConfig.dob,
                            children: item.USER_DOB,
                          },
                          {
                            label: labelConfig.role,
                            children: item.ROLE_NAME,
                          },
                          {
                            label: labelConfig.lastlogin,
                            children: item.USER_LAST_LOGIN,
                          },
                        ]}
                      />
                      {/* <div className="col-action">
                        <Button type="link" className="darkbtn">
                          <DoubleRightOutlined />
                          VIEW MORE
                        </Button>
                      </div> */}
                    </Card>
                  </Col>
                ))}
              </Row>
            )}
          </Card>
        </Col>
      </Row>
    </Main>
  );
};

const mapStateToProps = (state) => ({
  userData: state.AuthenticationData.userData,
});

export default connect(mapStateToProps, null)(ManageProfile);
