import { Provider } from "react-redux";
import {store, persistor} from "./redux/storeOf"
import MainRoutes from "./routes";
import "./App.css";

function App() {
  return (
    <Provider store={store}>
      <MainRoutes />
    </Provider>
  );
}

export default App;
